import React from 'react';
import logoImg from '../../../assets/images/ton_bebe_logo_g_t.png';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { Link } from 'react-router-dom';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className='login-header-wrapper bg-light-blue-babies' as='header'>
        <Row>
          <Col sm={ 12 } className='text-center'>
            <Link to='/'>
              <img src={ logoImg } className='login-header-logo' alt='Ton Bebe' />
            </Link>
          </Col>
        </Row>
        <Row className='items-middle'>
          <Col xs={ 12 } className='text-center'>
            <h2 className='login-header-message'>子供を望む全ての人に</h2>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Header;
