import React from 'react';
import Hero from './hero';
import UserHeader from '../common/user_header';
import MessageBand from './message_band';
import HomeContents from './home_contents';
import Footer from '../common/footer';
import Auth from '../../services/auth';
import { toast } from 'react-toastify';

class Home extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    const { action } = this.props.match.params;

    if (action === 'signup') {
      toast.success('Ton Bébéへようこそ！！');
    } else if (action === 'login') {
      toast.success('ログインしました。');
    } else if (action === 'activate') {
      toast.success(<span>Ton Bébéへようこそ！！<br/>ログインしてご利用ください。</span>);
    } else if (action === 'failed') {
      toast.error(<span>ログインに失敗しました。<br/>もう一度お試しください。</span>);
    } else if (action === 'unsubscribe') {
      toast.success('通知を停止しました。');
    } else if (action === 'unsubscribe_failed') {
      toast.error('通知の停止に失敗しました。');
    }
  }

  render() {
    let headerSet = <div></div>;
    if (Auth.isLoggedIn()) {
      headerSet =
        <div>
          <UserHeader />
          <MessageBand />
        </div>;
    } else {
      headerSet = <Hero />;
    }

    return (
      <div>
        { headerSet }
        <HomeContents />
        <Footer />
      </div>
    )
  }
}

export default Home;
