import React from 'react';
import PropTypes from 'prop-types';
import UserHeader from '../common/user_header';
import DonorMenus from './donor_menus';
import DonorProfile from './donor_profile'
import DonorAccount from './donor_account'
import DonorStd from './donor_std'
import DonorSemen from './donor_semen'
import DonorSubmit from './donor_submit'
import DonorStdSemen from './donor_std_semen'
import DonorRecord from './donor_record'
import Footer from '../common/footer';
import Auth from '../../services/auth';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'

class DonorMypages extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { menu, action } = this.props;

    let menuPage = null;
    switch (menu) {
      case 'profile':
        menuPage = <DonorProfile />;
        break;
      case 'account':
        menuPage = <DonorAccount />;
        break;
      case 'std_semen':
        if (action === 'std') {
          menuPage = <DonorStd />;
        } else if (action === 'semen') {
          menuPage = <DonorSemen />;
        } else if (action === 'submit') {
          menuPage = <DonorSubmit />;
        } else {
          menuPage = <DonorStdSemen />;
        }
        break;
      case 'record':
        menuPage = <DonorRecord action={ action }/>;
        break;
    }

    return (
      <div>
        <UserHeader />
        <Container>
          <Row>
            <Col md={ 3 } className='d-none d-md-block'>
              <DonorMenus activeMenu={ menu } />
            </Col>
            <Col md={ 9 } className='mypage-wrapper'>
              { menuPage }
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    )
  }
}

DonorMypages.propTypes = {
  menu: PropTypes.string,
  act: PropTypes.string
}

export default DonorMypages;
