import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap'
import SingleMessage from './single_message';
import ApiClient from '../../services/api_client';
import { toast } from 'react-toastify';
import Util from '../../services/util';

class MessageView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      message: '',
      message_rows: 4
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.active_conv_id) {
      this.execLoadMessages(nextProps.active_conv_id);
    }
  }

  execLoadMessages = (conv_id) => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/convs/${ conv_id }/messages`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Messages] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const msgs = json.data.map(msg => {
        return {
          user_no: msg.sender.user_no,
          name: msg.sender.name,
          sent_at: Util.toLocalDate(msg.sent_at).toLocaleString().slice(0,-3),
          body: msg.body,
          is_read: msg.is_read
        }
      })

      this.setState({
        messages: msgs
      }, this.execMessagesToRead(conv_id))
    }).catch((err) => {
      console.error(err);
    });
  }

  execMessagesToRead = (conv_id) => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/convs/${ conv_id }/update_to_read`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET UpdateToRead] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      // Do nothing.
    }).catch((err) => {
      console.error(err);
    });
  }

  handleMessageChange = (e) => {
    const line_cnt = (e.target.value.match(/\r\n|\n/g) || []).length + 1;
    this.setState({
      message: e.target.value,
      message_rows: Math.max(line_cnt, 4)
    })
  }

  handleSendMessage = (e) => {
    const { message } = this.state;
    const { active_conv_id, handleLoading } = this.props;

    if (message.length === 0) return;

    handleLoading(true);

    const data = {
      conv_id: active_conv_id,
      message_body: message
    }

    let req = ApiClient.securePostRequestTo(
      `/api/v1/convs/${ active_conv_id }/add_message`,
      data
    );

    fetch(req).then((res) => {
      handleLoading(false);

      if (!res.ok) {
        throw Error(`[Create conv/message] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      if (json.message) {
        toast.error(json.message);
      } else {
        toast.success('送信しました。');
        this.execLoadMessages(this.props.active_conv_id);
        this.setState({ message: '' });
      }
    }).catch((err) => {
      toast.error('送信に失敗しました。時間をおいてお試しください。');
    });
  }

  render() {
    const { messages } = this.state;

    let messageList = [];
    for (let i in messages) {
      messageList.push(<SingleMessage key={ i } message={ messages[i] } />)
    }

    return (
      <div className='message-view-wrapper'>
        <div className='message-compose-box'>
          <Row>
            <Col xs={ 12 }>
              <Form.Group>
                <Form.Control
                  as='textarea'
                  placeholder='返信する。'
                  value={ this.state.message }
                  onChange={ this.handleMessageChange }
                  rows={ this.state.message_rows }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='send-button'>
            <Col xs={{ span: 4, offset: 4 }}>
              <Button
                variant=''
                className='btn-ghost-dark' block
                onClick={ this.handleSendMessage }
                disabled={ this.state.message.length === 0 }
              >送信</Button>
            </Col>
          </Row>
        </div>
        <div className='message-view-box'>
          { messageList }
        </div>
      </div>
    )
  }
}

MessageView.propTypes = {
  active_conv_id: PropTypes.string,
  handleLoading: PropTypes.func
}

export default MessageView;
