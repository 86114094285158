import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
  Badge
} from 'react-bootstrap'
import ResultRow from './result_row';

class ResultTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { donors } = this.props;

    let donorList = [];
    for (let i in donors) {
      donorList.push(<ResultRow key={ i } donor={ donors[i] } />)
    }

    return (
      <Container className='result-table-wrapper'>
        <Row>
          <Col>
            <Table responsive hover>
              <thead>
                <tr>
                  <th></th>
                  <th>エリア</th>
                  <th>年齢/血液型</th>
                  <th>体型</th>
                  <th>学歴</th>
                  <th>婚姻歴</th>
                  <th>
                    <div className='items-middle'>
                      <span>検査結果</span>
                      <OverlayTrigger
                        placement='bottom'
                        overlay={
                          <Tooltip id='abcde'>
                            精子品質とは、精液検査の結果をA〜Eの５段階で表したものです。
                          </Tooltip>
                        }
                      >
                        <Badge pill variant='secondary'>?</Badge>
                      </OverlayTrigger>
                    </div>
                  </th>
                  <th>提供方法</th>
                </tr>
              </thead>
              <tbody>
                { donorList }
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    )
  }
}

ResultTable.propTypes = {
  donors: PropTypes.array
}

export default ResultTable;
