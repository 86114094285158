import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import VisitorHeader from '../common/visitor_header';
import UserHeader from '../common/user_header';
import PageTitle from '../common/page_title';
import Footer from '../common/footer';
import Auth from '../../services/auth';
import Flow from './flow';
import Method from './method';
import ToBeDonor from './to_be_donor';
import DonorManners from './donor_manners';
import Terms from './terms';
import Privacy from './privacy';
import Commerce from './commerce';
import Help from './help';

class Static extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let headerSet = null;
    if (Auth.isLoggedIn()) {
      headerSet = <UserHeader />
    } else {
      headerSet = <VisitorHeader />;
    }

    const { page } = this.props.match.params;

    let title, title_bg_class, content = null;
    switch (page) {
      case 'flow':
        title = '精子提供の流れ';
        title_bg_class = 'bg-light-pink-babies';
        content = <Flow />;
        break;
      case 'method':
        title = '精子提供の方法';
        title_bg_class = 'bg-light-pink-babies';
        content = <Method />;
        break;
      case 'to_be_donor':
        title = '精子ドナーになるには';
        title_bg_class = 'bg-light-blue-babies';
        content = <ToBeDonor />;
        break;
      case 'donor_manners':
        title = '精子ドナーの心得';
        title_bg_class = 'bg-light-blue-babies';
        content = <DonorManners />;
        break;
      case 'terms':
        title = '利用規約';
        title_bg_class = 'bg-light-blue-babies';
        content = <Terms />;
        break;
      case 'privacy':
        title = 'プライバシーポリシー';
        title_bg_class = 'bg-light-blue-babies';
        content = <Privacy />;
        break;
      case 'commerce':
        title = '特定商取引法に基づく表記';
        title_bg_class = 'bg-light-blue-babies';
        content = <Commerce />;
        break;
      case 'help':
        title = 'ヘルプ';
        title_bg_class = 'bg-light-blue-babies';
        content = <Help />;
        break;
    }

    return (
      <div>
        { headerSet }
        <PageTitle title={ title } bgClass={ title_bg_class } />
          { content }
        <Footer />
      </div>
    )
  }
}

export default Static;
