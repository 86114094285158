import React from 'react';
import {
  Row,
  Col,
  Button,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import Util from '../../../services/util';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner';

class SemenApproval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      semens: [],
      loading: false
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/admin/semen_approvals`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET SemenApprovals] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      let semens = json.data.map(semen => {
        const updated_at = new Date(semen.updated_at)
        return {
          id: semen.id,
          user_no: semen.user_id + 500,
          total: semen.total,
          status: semen.status,
          updated_at: updated_at.toLocaleString()
        }
      });
      this.setState({
        semens: semens
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { semens } = this.state;

    let applicationList = [];
    for (let i in semens) {
      applicationList.push(
        <tr key={i}>
          <th>{ semens[i].user_no }</th>
          <th>{ semens[i].total }</th>
          <th>{ Util.val('semen_status', semens[i].status) }</th>
          <th>{ semens[i].updated_at }</th>
          <th>
            <Link 
              to={ `/admin/semen_approval/${ semens[i].id }/edit` }
              className='btn btn-sm btn-ghost-dark'
            >
              詳細
            </Link>
          </th>
        </tr>
      );
    }


    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col sm={ 12 }>
            <h2 className='admin-title'>精子検査結果承認</h2>
          </Col>
        </Row>
        <div className='admin-content-wrapper'>
          <Row>
            <Col xs={ 12 } className='admin-applications'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ユーザーNO</th>
                    <th>結果</th>
                    <th>ステータス</th>
                    <th>提出日</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { applicationList }
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default SemenApproval;
