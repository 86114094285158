import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class PleaseLogin extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='profile-content-wrapper'>
        <Row>
          <Col className=''>
            <Row>
              <Col xs={ 12 } className='profile-content-title text-center'>
                <span>このドナーにメッセージを送るにはログインしてください。</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 12 } className='profile-content-item-d text-center'>
                <Link to='/login' className='btn-ghost-dark text-right btn btn-lg'>ログインページへ</Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default PleaseLogin;
