import jwtDecode from 'jwt-decode';
import { userTypes } from '../constants';

const getPayload = () => {
  const access_token = localStorage.getItem('access_token');
  return access_token ? jwtDecode(localStorage.getItem('access_token')) : null;
}

const getValidPayload = () => {
  const payload = getPayload();
  if (!payload) return null;

  const now = Math.floor((new Date).getTime() / 1000);
  if (payload.exp < now) return null;

  return payload;
}

const isLoggedIn = () => {
  const payload = getValidPayload();
  if (!payload) return false;

  const user_type = payload.user_type;
  return user_type === userTypes.DONOR ||
    user_type === userTypes.DONEE ||
    user_type === userTypes.ADMIN;
}

const isDonor = () => {
  const payload = getValidPayload();
  if (!payload) return false;

  const user_type = payload.user_type;
  return user_type === userTypes.DONOR;
}

const isDonee = () => {
  const payload = getValidPayload();
  if (!payload) return false;

  const user_type = payload.user_type;
  return user_type === userTypes.DONEE;
}

const isAdmin = () => {
  const payload = getValidPayload();
  if (!payload) return false;

  const user_type = payload.user_type;
  return user_type === userTypes.ADMIN;
}

const userNo = () => {
  const payload = getValidPayload();

  return payload ? payload.user_no : null;
}

const userId = () => {
  const payload = getValidPayload();

  return payload ? payload.user_id : null;
}

export default {
  isLoggedIn,
  isDonor,
  isDonee,
  isAdmin,
  userNo,
  userId
};
