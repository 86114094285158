import React from 'react';
import PropTypes from 'prop-types';
import Records from './records';
import NewRecord from './new_record';

class DonorRecord extends React.Component {
  render() {
    const { action } = this.props;
    const child = (action === 'new') ? <NewRecord /> : <Records />;

    return (
      <div>
        { child }
      </div>
    )
  }
}

DonorRecord.propTypes = {
  action: PropTypes.string
}

export default DonorRecord;
