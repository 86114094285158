import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Modal
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner';

class DonorAccount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      password: '',
      password_confirmation: '',
      show_confirm: false
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/users/${ Auth.userId() }`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET User] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const user = json.data;
      this.setState({
        email: user.email,
        password: '',
        password_confirmation: ''
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  updateAccount = () => {
    this.setState({ loading: true });
    const data = {
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.password_confirmation,
    }

    let req = ApiClient.securePatchRequestTo(
      `/api/v1/users/${ Auth.userId() }`,
      data
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Update] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success('保存しました。');
      const user = json.data;
      this.setState({
        email: user.email,
        password: '',
        password_confirmation: ''
      });
    }).catch((err) => {
      toast.error('保存に失敗しました。もう一度お試しください 。');
    });
  }

  showConfirm = () => {
    this.setState({ show_confirm: true })
  }

  closeConfirm = () => {
    this.setState({ show_confirm: false })
  }

  cancelAccount = () => {
    this.setState({ loading: true, show_confirm: false });

    let req = ApiClient.secureGetRequestTo(
      `/api/v1/users/${ Auth.userId() }/cancel`
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Cancel account] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');

      this.props.history.push('/');

      toast.success('退会しました');
    }).catch((err) => {
      toast.error('退会に失敗しました。もう一度お試しください 。');
    });
  }

  handleTextChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  render() {
    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col sm={ 12 }>
            <h2 className='mypage-title'>アカウント</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col xs={ 12 } className=''>
              <Row className='items-middle'>
                <Col md={{ span: 4, offset: 1 }} className='mypage-content-item-h'>
                  <span>Mail</span>
                </Col>
                <Col md={ 5 } className='mypage-content-item-d'>
                  <Form.Control
                    type='email'
                    id='email'
                    value={ this.state.email }
                    onChange={ this.handleTextChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 4, offset: 1 }} className='mypage-content-item-h'>
                  <span>パスワード</span>
                </Col>
                <Col md={ 5 } className='mypage-content-item-d'>
                  <Form.Control
                    type='password'
                    id='password'
                    value={ this.state.password }
                    onChange={ this.handleTextChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 4, offset: 1 }} className='mypage-content-item-h'>
                  <span>パスワード（確認）</span>
                </Col>
                <Col md={ 5 } className='mypage-content-item-d'>
                  <Form.Control
                    type='password'
                    id='password_confirmation'
                    value={ this.state.password_confirmation }
                    onChange={ this.handleTextChange }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 1 }} className='mypage-submit'>
              <Button
                className='btn-ghost-dark'
                onClick={ this.updateAccount }
                block
              >
                変更を保存
              </Button>
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm={ 12 }>
            <h2 className='mypage-title'>退会</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col md={{ span: 4, offset: 1 }} className='mypage-submit'>
              <Button
                className='btn-ghost-danger'
                onClick={ this.showConfirm }
                block
              >
                退会する
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 11, offset: 1 }} className='mypage-submit'>
              <p>＊退会すると全ての登録情報が削除されます。</p>
            </Col>
          </Row>
        </div>
        <Modal
          show={ this.state.show_confirm }
          centered
        >
          <Modal.Body>退会すると全ての登録情報が削除されます。<br/> 退会しますか？</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ this.closeConfirm }>
              いいえ
            </Button>
            <Button variant="danger" onClick={ this.cancelAccount }>
              はい、退会します
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default withRouter(DonorAccount);
