import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import Util from '../../services/util';
import * as escape from 'escape-html';

class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { donor } = this.props;

    let areas = donor.areas;
    let areas_html = '';
    for (let i = 0; i < areas.length; i++) {
      if (i > 0) { areas_html += '・' }
      areas_html += Util.val('area', areas[i]);
    }

    let comments = escape(donor.comments);
    if (comments) {
      comments = comments.split("<").join("&lt;");
      comments = comments.split(">").join("&gt;");
      comments = comments.split("\n").join("<br>");
    }

    return (
      <Container className='profile-content-wrapper'>
        <Row>
          <Col className='profile-content-box'>
            <Row>
              <Col md={ 12 } className='profile-content-title'>
                <span>基本情報</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>名前</span>
              </Col>
              <Col xs={ 7 } md={ 3 } className='profile-content-item-d'>
                <span>{ donor.name.split('').join('.') + '.' }</span>
              </Col>
              <Col xs={ 5 } md={ 2 } className='profile-content-item-h'>
                <span>提供実績</span>
              </Col>
              <Col xs={ 7 } md={ 4 } className='profile-content-item-d'>
                <span>{ donor.count } 回</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>年齢</span>
              </Col>
              <Col xs={ 7 } md={ 3 } className='profile-content-item-d'>
                <span>{ donor.age } 歳</span>
              </Col>
              <Col xs={ 5 } md={ 2 } className='profile-content-item-h'>
                <span>最終ログイン</span>
              </Col>
              <Col xs={ 7 } md={ 4 } className='profile-content-item-d'>
                <span>{ donor.last_activity_on }</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>エリア</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ areas_html }</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>最終学歴</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>
                  { donor.college || Util.val('college_type', donor.college_type) }
                  &nbsp;&nbsp;
                  { donor.major } { donor.major_type ? `(${Util.val('major_type', donor.major_type)})` : ''}
                </span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>婚姻歴</span>
              </Col>
              <Col xs={ 7 } md={ 3 } className='profile-content-item-d'>
                <span>{ Util.val('marriage', donor.marriage) }</span>
              </Col>
              <Col xs={ 5 } md={ 2 } className='profile-content-item-h'>
                <span>子供</span>
              </Col>
              <Col xs={ 7 } md={ 4 } className='profile-content-item-d'>
                <span>男: { donor.son } 人&nbsp;&nbsp;&nbsp;女: { donor.daughter } 人</span>
              </Col>
            </Row>
            <Row className=''>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>自己PR</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span dangerouslySetInnerHTML={{ __html: comments }} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

BasicInfo.propTypes = {
  donor: PropTypes.object
}

export default BasicInfo;
