import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'

class StdInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { std } = this.props;

    let cert_text = '※診断書未登録';
    let cert_class = 'text-red';
    let total_text = '---';
    let collected_on = '---';
    if (std.certified) {
      cert_text = '※診断書登録済';
      cert_class = 'text-blue';
      total_text = '◎（陽性なし）';
      collected_on = std.collected_on.replace(/-/g, '/');
    }

    return (
      <Container className='profile-content-wrapper'>
        <Row>
          <Col className='profile-content-box'>
            <Row>
              <Col md={ 12 } className='profile-content-title'>
                <span>性病検査結果<small className={ cert_class }>{ cert_text }</small></span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-lg-item-h'>
                <span>検査結果</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-lg-item-d'>
                <span className=''>{ total_text }</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>採取日</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span className=''>{ collected_on }</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

StdInfo.propTypes = {
  std: PropTypes.object
}

export default StdInfo;
