import React from 'react';
import {
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ApiClient from '../../../services/api_client';
import { ja } from '../../../locales/ja';
import { toast } from 'react-toastify';

class NewRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      donees: [],
      donee_id: '',
      donated_at: '',
      area: '',
      method: ''
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/convs/index_for_records`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET convs for records] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const donees = json.data || [];
      const now = new Date();
      const today = now.getFullYear()
        + '-' + ('0' + (now.getMonth() + 1)).slice(-2)
        + '-' + ('0' + now.getDate()).slice(-2);

      this.setState({
        donees: donees,
        donee_id: donees.length > 0 ? donees[0].donee_id : '',
        donated_at: new Date(today),
        area: 'TOKYO',
        method: 'SYRINGE'
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  handleSaveClick = (e) => {
    let req = ApiClient.securePostRequestTo(
      `/api/v1/donor_records`,
      {
        donee_id: this.state.donee_id,
        donated_at: this.state.donated_at,
        area: this.state.area,
        meth: this.state.method
      }
    );

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[POST records] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success('登録しました。');
      this.props.history.push('/mypage/record');
    }).catch((err) => {
      toast.error('登録に失敗しました。時間をおいてお試しください。');
    });
  }

  handleDonatedAtChange = (date) => {
    this.setState({
      donated_at: date
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  render() {
    const { donees } = this.state;

    let doneeList = [];
    for (let i in donees) {
      const donee = donees[i];
      doneeList.push(
        <option key={ i } value={ donee.donee_id }>
          { donee.name } (No. { donee.donee_no })
        </option>
      )
    }

    const areas = ja['area'];
    let areaList = [];
    for (let key in areas) {
      areaList.push(
        <option key={ key } value={ key }>
          { areas[key] }
        </option>
      )
    }

    return (
      <div>
        <Row>
          <Col>
            <h2 className='mypage-title'>提供実績登録</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>提供を受けた人</span>
                </Col>
                <Col md={ 6 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='donee_id'
                    value={ this.state.donee_id }
                    onChange={ this.handleSelectChange }
                  >
                    { doneeList }
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>提供した日</span>
                </Col>
                <Col md={ 6 } className='mypage-content-item-d'>
                  <DatePicker
                    className='form-control'
                    dateFormat='yyyy/MM/dd'
                    selected={ this.state.donated_at }
                    onChange={ this.handleDonatedAtChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>エリア</span>
                </Col>
                <Col md={ 6 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='area'
                    value={ this.state.area }
                    onChange={ this.handleSelectChange }
                  >
                    { areaList }
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>提供方法</span>
                </Col>
                <Col md={ 6 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='method'
                    value={ this.state.method }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='SYRINGE'>シリンジ法</option>
                    <option value='TIMING'>タイミング法</option>
                  </Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-item-group'>
              <Row className='items-middle'>
                <Col md={{ span: 10, offset: 1 }} className=''>
                  <p>＊提供を受けた人はメッセージ履歴のある方からのみ選択可能です。</p>
                  <p>＊公開されるのは実績回数の合計値のみです。</p>
                  <p>＊提供を受けた人の名前などが公開されることはありません。</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 1 }} className='mypage-submit'>
              <Button
                variant=''
                className='btn-ghost-dark'
                disabled={ !this.state.donee_id || !this.state.donated_at }
                onClick={ this.handleSaveClick }
                block
              >実績登録</Button>
            </Col>
            <Col md={ 4 } className='mypage-submit'>
              <Link to='/mypage/record' className='btn-ghost-dark btn btn-block'>
                戻る
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default withRouter(NewRecord);
