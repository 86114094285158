import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import Util from '../../../services/util';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner';

class SemenApprovalEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user_no: '',
      collected_on: '',
      volume: 0.0,
      concentration: 0,
      total_count: 0,
      motility: 0,
      morphology: 0,
      total: '',
      status: '',
      comments: '',
      files: [],
      secret_url: ''
    }
  }

  componentWillMount = () => {
    const { id } = this.props;
    let req = ApiClient.secureGetRequestTo(`/api/v1/admin/semen_approvals/${ id }`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Semen] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const donor_semen = json.data || {};
      this.setState({
        user_no: donor_semen.user_no,
        collected_on: donor_semen.collected_on,
        volume: donor_semen.volume || '',
        concentration: donor_semen.concentration || '',
        total_count: donor_semen.total_count || '',
        motility: donor_semen.motility || '',
        morphology: donor_semen.morphology || '',
        total: donor_semen.total || '---',
        status: donor_semen.status || '',
        comments: donor_semen.comments || '',
        files: donor_semen.donor_semen_files || []
      }, this.setTotal);
    }).catch((err) => {
      console.error(err);
    });
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, this.setTotal)
  }

  handleTextChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, this.setTotal)
  }

  setTotal = () => {
    const { volume, concentration, total_count, motility, morphology } = this.state;

    let params = 'volume=' + volume;
    params += '&concentration=' + concentration;
    params += '&total_count=' + total_count;
    params += '&motility=' + motility;
    params += '&morphology=' + morphology;

    let req = ApiClient.getRequestTo(`/api/v1/donor_semens_total?${ params }`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Semen Total] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const total = json.data || '---';
      this.setState({ total: total })
    }).catch((err) => {
      console.error(err);
    });
  }

  handleSaveClick = (e) => {
    this.setState({ loading: true });

    const data = {
      volume: this.state.volume,
      concentration: this.state.concentration,
      total_count: this.state.total_count,
      motility: this.state.motility,
      morphology: this.state.morphology,
      comments: this.state.comments,
      status: this.state.status
    }

    let req = ApiClient.securePatchRequestTo(
      `/api/v1/admin/semen_approvals/${ this.props.id }`,
      data
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Update Semen] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success('保存しました。');
    }).catch((err) => {
      toast.error('保存に失敗しました。もう一度お試しください 。');
    });
  }

  find_semen_file_url = (e) => {
    const semen_file_id = e.currentTarget.dataset.semen_file_id;
    let req = ApiClient.secureGetRequestTo(`/api/v1/admin/semen_approvals/${ semen_file_id }/show_semen_file_url`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Semen file url] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const url = json.data || '';
      this.setState({
        secret_url: url
      })
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { loading, files, status, comments, secret_url } = this.state;

    let fileList = [];
    for (let idx in files) {
      fileList.push(
        <p key={ idx }>
          <Button
            className='btn-ghost-dark'
            onClick={ this.find_semen_file_url }
            data-semen_file_id={ files[idx].id }
            block
          >
            { (idx * 1 + 1) + ' : ' + files[idx].created_at }
          </Button>
        </p>
      );
    }

    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col sm={ 12 }>
            <h2 className='admin-title'>精子検査結果詳細</h2>
          </Col>
        </Row>
        <div className='admin-content-wrapper'>
          <Row>
            <Col xs={ 12 } className='admin-applications'>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>ユーザーNO</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <span>
                    <Link to={ `/donor/${ this.state.user_no }` } target='_blank'>
                      { this.state.user_no }
                    </Link>
                  </span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 6, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>採取日</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <span>{ this.state.collected_on.replace(/-/g, '/') }</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>精液量 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    step='0.1'
                    id='volume'
                    value={ this.state.volume }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 1 } className='mypage-content-item-d'>
                  <span>ml</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>精子濃度 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='concentration'
                    value={ this.state.concentration }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 1 } className='mypage-content-item-d'>
                  <span>万/ml</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>総精子数 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='total_count'
                    value={ this.state.total_count }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 1 } className='mypage-content-item-d'>
                  <span>万</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>運動率 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='motility'
                    value={ this.state.motility }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 1 } className='mypage-content-item-d'>
                  <span>%</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>正常形態率 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='morphology'
                    value={ this.state.morphology }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 1 } className='mypage-content-item-d'>
                  <span>%</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 6, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-lg-item-h'>
                  <span>総合判定</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-lg-item-d'>
                  <span className=''>{ this.state.total }</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } className='mypage-content-item-d'>
                  { fileList }
                </Col>
                <Col xs={ 12 } className='mypage-content-item-d'>
                  <a href={ secret_url } target="_blank">
                    { secret_url }
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 6, offset: 1 }} className='mypage-submit'>
              <Form.Control className='mypage-comments'
                as='textarea'
                id='comments'
                value={ comments }
                placeholder=''
                onChange={ this.handleTextChange }
              />
            </Col>
          </Row>
          <Row className='items-middle'>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
              <span>ステータス</span>
            </Col>
            <Col xs={ 3 } className='mypage-content-item-d'>
              <Form.Control
                as='select'
                id='status'
                value={ this.state.status }
                onChange={ this.handleSelectChange }
              >
                <option value='DRAFT'>未提出</option>
                <option value='SUBMITTED'>確認待ち</option>
                <option value='REMANDED'>要修正</option>
                <option value='CERTIFIED'>承認済み</option>
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-submit'>
              <Button
                className='btn-ghost-dark'
                onClick={ this.handleSaveClick }
                block
              >
                保存
              </Button>
            </Col>
            <Col xs={ 3 } className='mypage-submit'>
              <Link to='/admin/semen_approval' className='btn-ghost-dark btn btn-block'>
                戻る
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default SemenApprovalEdit;
