import React from 'react';
import SignupConfirmHeader from './signup_confirm_header';
import SignupConfirmContents from './signup_confirm_contents';

class SignupConfirm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <SignupConfirmHeader />
        <SignupConfirmContents email={ this.props.match.params.email } />
      </div>
    )
  }
}

export default SignupConfirm;
