import React from 'react';
import ReactDOM from 'react-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image
} from 'react-bootstrap'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ApiClient from '../../services/api_client';
import { toast } from 'react-toastify';

class ForgotForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: ''
    }
  }

  componentDidMount(){
    this.focusEmail();
  }

  focusEmail = () => {
    let input = ReactDOM.findDOMNode(this.refs.emailInput);
    input && input.select();
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handleSend = (e) => {
    if (!this.state.email) {
      return;
    }

    if (!this.state.email.match(/^([a-z0-9_\.\-])+@([a-z0-9_\.\-])+\.[a-z]+/i)) {
      toast.error(<span>メールアドレスを正しく入力してください。</span>);
      return;
    }

    let req = ApiClient.postRequestTo(
      '/api/v1/password_resets',
      {
        email: this.state.email
      }
    );

    fetch(req).then((res) => {
      console.log(res)
      if (!res.ok) {
        toast.error(<span>送信に失敗しました。<br/>もう一度お試しください。</span>);
        this.focusEmail();
        throw Error(`[POST password reset] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success(<span>パスワードリセットメールを送信しました。</span>);
      this.props.history.push('/');
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    return (
      <Container className='login-form-wrapper'>
        <Row className='or-row'>
          <Col sm={{ span: 4, offset: 4}} className=''>
             <p>メールアドレスを入力して送信してください。</p>
          </Col>
        </Row>
        <Form>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 2, offset: 2}} className='text-right'>
              Email
            </Form.Label>
            <Col sm={ 4 }>
              <Form.Control
                ref='emailInput'
                type='email'
                value={ this.state.email }
                placeholder='ton@bebe.jp'
                onChange={ this.handleEmailChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 2, offset: 4 }}>
              <Link
                to='#'
                onClick={ this.handleSend }
                className='btn btn-ghost-dark'
              >送信</Link>
            </Form.Label>
          </Form.Group>
        </Form>
      </Container>
    )
  }
}

export default withRouter(ForgotForm);
