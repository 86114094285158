import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import Util from '../../../services/util';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner';

class StdApprovalEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user_no: '',
      collected_on: '',
      hiv: '',
      syphilis: '',
      chlamydia : '',
      gonorrhea: '',
      trichomoniasis: '',
      candidiasis: '',
      hepatitis_b: '',
      hepatitis_c: '',
      total: '',
      status: '',
      comments: '',
      files: [],
      secret_url: ''
    }
  }

  componentWillMount = () => {
    const { id } = this.props;
    let req = ApiClient.secureGetRequestTo(`/api/v1/admin/std_approvals/${ id }`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Std] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const donor_std = json.data || {};
      this.setState({
        user_no: donor_std.user_no,
        collected_on: donor_std.collected_on,
        hiv: donor_std.hiv || 'MINUS',
        syphilis: donor_std.syphilis || 'MINUS',
        chlamydia : donor_std.chlamydia || 'MINUS',
        gonorrhea: donor_std.gonorrhea || 'MINUS',
        trichomoniasis: donor_std.trichomoniasis || 'NA',
        candidiasis: donor_std.candidiasis || 'NA',
        hepatitis_b: donor_std.hepatitis_b || 'NA',
        hepatitis_c: donor_std.hepatitis_c || 'NA',
        total: donor_std.total || '---',
        status: donor_std.status || '',
        comments: donor_std.comments || '',
        files: donor_std.donor_std_files || []
      }, this.setTotal);
    }).catch((err) => {
      console.error(err);
    });
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, this.setTotal)
  }

  handleTextChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  setTotal = () => {
    const keys = ['hiv','syphilis','chlamydia','gonorrhea','trichomoniasis','candidiasis','hepatitis_b','hepatitis_c']
    for (let i=0; i < keys.length; i++) {
      if (this.state[keys[i]] == 'PLUS') {
        this.setState({total: 'PLUS'})
        break
      }
      if (i === keys.length-1) {
        this.setState({total: 'MINUS'})
      }
    }
  }

  handleSaveClick = (e) => {
    this.setState({ loading: true });

    const data = {
      hiv: this.state.hiv,
      syphilis: this.state.syphilis,
      chlamydia: this.state.chlamydia,
      gonorrhea: this.state.gonorrhea,
      trichomoniasis: this.state.trichomoniasis,
      candidiasis: this.state.candidiasis,
      hepatitis_b: this.state.hepatitis_b,
      hepatitis_c: this.state.hepatitis_c,
      comments: this.state.comments,
      status: this.state.status
    }

    let req = ApiClient.securePatchRequestTo(
      `/api/v1/admin/std_approvals/${ this.props.id }`,
      data
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Update Std] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success('保存しました。');
    }).catch((err) => {
      toast.error('保存に失敗しました。もう一度お試しください 。');
    });
  }

  find_std_file_url = (e) => {
    const std_file_id = e.currentTarget.dataset.std_file_id;
    let req = ApiClient.secureGetRequestTo(`/api/v1/admin/std_approvals/${ std_file_id }/show_std_file_url`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Std file url] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const url = json.data || '';
      this.setState({
        secret_url: url
      })
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { loading, total, files, status, comments, secret_url } = this.state;

    const total_text = total === 'PLUS' ? '陽性あり' : '陽性なし';
    const total_class = total === 'PLUS' ? 'text-red' : '';

    let fileList = [];
    for (let idx in files) {
      fileList.push(
        <p key={ idx }>
          <Button
            className='btn-ghost-dark'
            onClick={ this.find_std_file_url }
            data-std_file_id={ files[idx].id }
            block
          >
            { (idx * 1 + 1) + ' : ' + files[idx].created_at }
          </Button>
        </p>
      );
    }

    return (
      <div>
        <Spinner isLoading={ loading } />
        <Row>
          <Col sm={ 12 }>
            <h2 className='admin-title'>性病検査結果詳細</h2>
          </Col>
        </Row>
        <div className='admin-content-wrapper'>
          <Row>
            <Col xs={ 12 } className=''>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>ユーザーNO</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <span>
                    <Link to={ `/donor/${ this.state.user_no }` } target='_blank'>
                      { this.state.user_no }
                    </Link>
                  </span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 6, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>採取日</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <span>{ this.state.collected_on.replace(/-/g, '/') }</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>HIV *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='hiv'
                    value={ this.state.hiv }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>梅毒 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='syphilis'
                    value={ this.state.syphilis }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>クラミジア *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='chlamydia'
                    value={ this.state.chlamydia }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>淋菌 *</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='gonorrhea'
                    value={ this.state.gonorrhea }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>トリコモナス</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='trichomoniasis'
                    value={ this.state.trichomoniasis }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>カンジダ</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='candidiasis'
                    value={ this.state.candidiasis }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>B型肝炎</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='hepatitis_b'
                    value={ this.state.hepatitis_b }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>C型肝炎</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='hepatitis_c'
                    value={ this.state.hepatitis_c }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 6, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 3, offset: 1 }} className='mypage-content-lg-item-h'>
                  <span>総合判定</span>
                </Col>
                <Col xs={ 3 } className='mypage-content-lg-item-d'>
                  <span className={ total_class }>{ total_text }</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } className='mypage-content-item-d'>
                  { fileList }
                </Col>
                <Col xs={ 12 } className='mypage-content-item-d'>
                  <a href={ secret_url } target="_blank">
                    { secret_url }
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 6, offset: 1 }} className='mypage-submit'>
              <Form.Control className='mypage-comments'
                as='textarea'
                id='comments'
                value={ comments }
                placeholder=''
                onChange={ this.handleTextChange }
              />
            </Col>
          </Row>
          <Row className='items-middle'>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
              <span>ステータス</span>
            </Col>
            <Col xs={ 3 } className='mypage-content-item-d'>
              <Form.Control
                as='select'
                id='status'
                value={ this.state.status }
                onChange={ this.handleSelectChange }
              >
                <option value='DRAFT'>未提出</option>
                <option value='SUBMITTED'>確認待ち</option>
                <option value='REMANDED'>要修正</option>
                <option value='CERTIFIED'>承認済み</option>
              </Form.Control>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-submit'>
              <Button
                className='btn-ghost-dark'
                onClick={ this.handleSaveClick }
                block
              >
                保存
              </Button>
            </Col>
            <Col xs={ 3 } className='mypage-submit'>
              <Link to='/admin/std_approval' className='btn-ghost-dark btn btn-block'>
                戻る
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default StdApprovalEdit;
