import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col
} from 'react-bootstrap'
import Auth from '../../services/auth';
import Util from '../../services/util';
import * as escape from 'escape-html';

class SingleMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { message } = this.props;

    const is_from_me = message.user_no == Auth.userNo();

    const clsUnread = !is_from_me && !message.is_read ? 'unread' : ''

    let body = escape(message.body);
    if (body) {
      body = body.split("<").join("&lt;");
      body = body.split(">").join("&gt;");
      body = body.split("\n").join("<br>");
      body = Util.autoLink(body);
    }

    return (
      <div className={ `message-view-single-message ${ clsUnread }` }>
        <Row className='items-middle'>
          <Col sm={ 4 } className='message-view-sender'>
            <span>{ message.name }</span>
          </Col>
          <Col sm={ 8 } className='message-view-sent_at'>
            <span>{ message.sent_at }</span>
          </Col>
        </Row>
        <Row>
          <Col sm={ 12 } className='message-view-message'>
            <p dangerouslySetInnerHTML={{ __html: body }} />
          </Col>
        </Row>
      </div>
    )
  }
}

SingleMessage.propTypes = {
  message: PropTypes.object
}

export default SingleMessage;
