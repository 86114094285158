import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Alert
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ApiClient from '../../../services/api_client';
import Util from '../../../services/util';
import { Spinner } from '../../common/spinner';

class DonorStd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collected_on: '',
      hiv: '',
      syphilis: '',
      chlamydia : '',
      gonorrhea: '',
      trichomoniasis: '',
      candidiasis: '',
      hepatitis_b: '',
      hepatitis_c: '',
      total: '',
      status: '',
      expired: false,
      comments: '',
      files: [],
      del_file_ids: []
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donor_stds/me`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Std] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const donor_std = json.data || {};
      if (donor_std.status == 'CERTIFIED') {
        donor_std.comments = '';
        donor_std.donor_std_files = [];
      }
      this.setState({
        collected_on: donor_std.collected_on ? new Date(donor_std.collected_on) : '',
        hiv: donor_std.hiv || 'MINUS',
        syphilis: donor_std.syphilis || 'MINUS',
        chlamydia : donor_std.chlamydia || 'MINUS',
        gonorrhea: donor_std.gonorrhea || 'MINUS',
        trichomoniasis: donor_std.trichomoniasis || 'NA',
        candidiasis: donor_std.candidiasis || 'NA',
        hepatitis_b: donor_std.hepatitis_b || 'NA',
        hepatitis_c: donor_std.hepatitis_c || 'NA',
        total: donor_std.total || '---',
        status: donor_std.status || '',
        expired: donor_std.expired || false,
        comments: donor_std.comments || '',
        files: donor_std.donor_std_files || [],
        del_file_ids: []
      }, this.setTotal);
    }).catch((err) => {
      console.error(err);
    });
  }

  handleDateChange = (date) => {
    this.setState({
      collected_on: date
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, this.setTotal)
  }

  setTotal = () => {
    const keys = ['hiv','syphilis','chlamydia','gonorrhea','trichomoniasis','candidiasis','hepatitis_b','hepatitis_c']
    for (let i=0; i < keys.length; i++) {
      if (this.state[keys[i]] == 'PLUS') {
        this.setState({total: 'PLUS'})
        break
      }
      if (i === keys.length-1) {
        this.setState({total: 'MINUS'})
      }
    }
  }

  handleFileToSelect = (e) => {
    this.fileSelector.click();
  }

  handleFileSelected = (e) => {
    let files = this.state.files;
    if (files.length >= 5) { return }
    files.push({
      image: e.target.files.item(0),
      new: true
    });
    this.setState({
      files: files
    });
  }

  handleFileRemove = (e) => {
    const file_idx = e.currentTarget.dataset.file_idx;
    let { files, del_file_ids } = this.state

    let file = files[file_idx]
    if (!file['new']) {
      del_file_ids.push(file['id'])
    }

    files.splice(file_idx, 1);

    this.setState({
      files: files,
      del_file_ids: del_file_ids
    });
  }

  handleSaveClick = (e) => {
    if (this.state.status == 'CERTIFIED' && !this.state.expired) {
      if (!confirm('保存すると再申請＆承認されるまで未提出扱いとなります。保存しますか？')){
        return;
      }
    }

    const { collected_on } = this.state;
    if (collected_on && !Util.isIn6Month(collected_on)) {
      toast.error('6ヶ月以内の検査結果のみ登録可能です。');
      return
    }

    this.execSave(e);
  }

  execSave = (e) => {
    if (this.state.status == 'REMANDED') {
      const { collected_on, hiv, syphilis, chlamydia, gonorrhea, files } = this.state;
      if (!collected_on || !hiv || !syphilis || !chlamydia || !gonorrhea || !files || files.length == 0) {
        toast.error('＊は必須項目です');
        return
      }
    }

    this.setState({ loading: true });

    const act = this.state.status == 'REMANDED' ? '再提出' : '保存'

    const formData = new FormData();
    formData.append('collected_on', this.state.collected_on);
    formData.append('hiv', this.state.hiv);
    formData.append('syphilis', this.state.syphilis);
    formData.append('chlamydia', this.state.chlamydia);
    formData.append('gonorrhea', this.state.gonorrhea);
    formData.append('trichomoniasis', this.state.trichomoniasis);
    formData.append('candidiasis', this.state.candidiasis);
    formData.append('hepatitis_b', this.state.hepatitis_b);
    formData.append('hepatitis_c', this.state.hepatitis_c);
    formData.append('del_file_ids', this.state.del_file_ids);
    for (const idx in this.state.files) {
      const f = this.state.files[idx]
      if (f['new']) {
        formData.append('files[]', f['image']);
      }
    }

    const token = localStorage.getItem('access_token');

    const req =  new Request('/api/v1/donor_stds/me', {
      method: 'PATCH',
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      }),
      body: formData
    });

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Save Std] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success(act + 'しました。');
      this.props.history.push('/mypage/std_semen');
    }).catch((err) => {
      toast.error('保存に失敗しました。時間をおいてお試しください。');
    });
  }

  render() {
    const { loading, total, files, status, comments } = this.state;

    let alert_message = null;
    if (status == 'REMANDED') {
      let comments_e = comments;
      comments_e = comments_e.split("<").join("&lt;");
      comments_e = comments_e.split(">").join("&gt;");
      comments_e = comments_e.split("\n").join("<br>");

      alert_message =
        <Alert variant='danger'>
          <span dangerouslySetInnerHTML={{ __html: comments_e }} />
        </Alert>
    }

    const total_text = total === 'PLUS' ? '陽性あり' : '陽性なし';
    const total_class = total === 'PLUS' ? 'text-red' : '';

    let fileList = [];
    if (files.length === 0) {
      fileList.push(<p key='0' className='text-center upload-copy-msg'>-- 書類のコピーを提出してください --</p>)
    } else {
      for (let idx in files) {
        fileList.push(
          <p key={ idx }>
            <span className='d-md-none'>{ idx * 1 + 1 }.&nbsp;</span>
            { files[idx]['image'].name }&nbsp;&nbsp;
            <Button
              variant=''
              className='btn-link-dark btn-lg'
              data-file_idx={ idx }
              onClick={ this.handleFileRemove }
            >
              &times;
            </Button>
          </p>
        );
      }
    }

    const save_btn_text = status == 'REMANDED' ? '再提出' : '保存'

    return (
      <div>
        <Spinner isLoading={ loading } />
        <Row>
          <Col>
            <h2 className='mypage-title'>性病検査結果</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col className='mypage-item-group'>
              { alert_message }
            </Col>
          </Row>
          <Row>
            <Col className='mypage-item-group'>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>採取日 *</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <DatePicker
                    className='form-control'
                    dateFormat='yyyy/MM/dd'
                    selected={ this.state.collected_on }
                    onChange={ this.handleDateChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 7, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>HIV *</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='hiv'
                    value={ this.state.hiv }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>梅毒 *</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='syphilis'
                    value={ this.state.syphilis }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>クラミジア *</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='chlamydia'
                    value={ this.state.chlamydia }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>淋菌 *</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='gonorrhea'
                    value={ this.state.gonorrhea }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>トリコモナス</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='trichomoniasis'
                    value={ this.state.trichomoniasis }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>カンジダ</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='candidiasis'
                    value={ this.state.candidiasis }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>B型肝炎</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='hepatitis_b'
                    value={ this.state.hepatitis_b }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>C型肝炎</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='hepatitis_c'
                    value={ this.state.hepatitis_c }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='NA'>検査対象外</option>
                    <option value='MINUS'>陰性</option>
                    <option value='PLUS'>陽性</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 7, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 5, offset: 1 }} md={{ span: 3, offset: 1 }} className='mypage-content-lg-item-h'>
                  <span>総合判定</span>
                </Col>
                <Col xs={ 6 } md={ 4 } className='mypage-content-lg-item-d'>
                  <span className={ total_class }>{ total_text }</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-item-group'>
              <Row className=''>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>検査結果書類 *</span>
                </Col>
                <Col md={ 8 } className='mypage-content-item-d'>
                  <Row>
                    <Col className='mypage-content-item-d'>
                      { fileList }
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 8 } lg={ 6 }>
                      <Button
                        className='btn-ghost-dark'
                        disabled={ files.length >= 5}
                        onClick={ this.handleFileToSelect }
                        block
                      >
                        追加アップロード
                      </Button>
                      <input
                        type='file'
                        className='invisible'
                        ref={el => this.fileSelector = el}
                        onChange={ this.handleFileSelected }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } className='mypage-item-group'>
              <Row className='items-middle'>
                <Col md={{ span: 10, offset: 1 }} className=''>
                  <h4>【注意事項】</h4>
                  <p>＊検査結果書類は<strong className='text-orange'>「検査機関」</strong>と<strong className='text-orange'>「検査ID（報告書番号、キットIDなど）」</strong>がわかるものを提出してください。</p>
                  <p>＊病院での検査結果の場合、<strong className='text-orange'>「診察券」「診療明細」</strong>のコピーも提出してください。</p>
                  <p>＊提出書類をもとに検査機関に問い合わせを行う場合があります。</p>
                  <p>＊書類の信憑性を確認できない場合は登録をお断りします。書類の偽造など悪質な場合は、返金致しませんのでご了承ください。</p>
                  <p>＊書類の<strong className='text-red'>偽造/変造は犯罪</strong>です。刑法により処罰される可能性があります。</p>
                  <h4>【入力項目と書類について】</h4>
                  <p>＊１つでも「陽性」がある場合、登録申請できません。</p>
                  <p>＊検査結果書類はウェブ画面のスクリーンショットでも可能です。</p>
                  <p>＊検査結果書類の画像を他ユーザーが閲覧することはありません。</p>
                  <p>＊採取日の記載が書類にない場合は、検査日/受付日などの日付でも登録可能です。</p>
                  <p>＊書類に記載のある項目は全て記入してください。記入漏れがある場合は、運営事務局にて書類を基に訂正を行います。</p>
                  <p>＊検査結果の有効期間は採取日から１年としております。</p>
                  <p>＊6ヶ月以内の検査結果のみ登録可能です。</p>
                  <p>＊個人情報の取り扱いについては<Link to='/static/privacy'>プライバシーポリシー</Link>のページをご覧ください。</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 3, offset: 1 }} className='mypage-submit'>
              <Button
                className='btn-ghost-dark'
                onClick={ this.handleSaveClick }
                block
              >
                { save_btn_text }
              </Button>
            </Col>
            <Col md={ 3 } className='mypage-submit'>
              <Link to='/mypage/std_semen' className='btn-ghost-dark btn btn-block'>
                戻る
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default withRouter(DonorStd);
