import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'

class PageTitle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const bg_class = this.props.bgClass ? this.props.bgClass : 'bg-light-pink-babies'
    return (
      <Container fluid className={ `page-title-wrapper ${ bg_class }`}>
        <Row>
          <Col xs={{ span: 10, offset: 1 }}>
            <h2 className='page-title'>{ this.props.title }</h2>
          </Col>
        </Row>
      </Container>
    )
  }
}

PageTitle.propTypes = {
  title: PropTypes.string,
  bgClass: PropTypes.string
}

export default PageTitle;
