import React from 'react';
import {
  Row,
  Col,
  Alert
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import Util from '../../../services/util';

class DonorStdSemen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      std: {
        collected_on: '',
        hiv: '',
        syphilis: '',
        chlamydia : '',
        gonorrhea: '',
        trichomoniasis: '',
        candidiasis: '',
        hepatitis_b: '',
        hepatitis_c: '',
        total: '',
        status: '',
        expired: false,
        files: []
      },
      semen: {
        collected_on: '',
        volume: '',
        concentration: '',
        total_count: '',
        motility: '',
        morphology: '',
        total: '',
        status: '',
        expired: false,
        files: []
      }
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donors/${ Auth.userId() }/show_std_semen`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET StdSemen] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const donor_std = json.data.std || {};
      const donor_semen = json.data.semen || {};

      this.setState({
        std: {
          collected_on: donor_std.collected_on || '',
          hiv: donor_std.hiv || '',
          syphilis: donor_std.syphilis || '',
          chlamydia : donor_std.chlamydia || '',
          gonorrhea: donor_std.gonorrhea || '',
          trichomoniasis: donor_std.trichomoniasis || '',
          candidiasis: donor_std.candidiasis || '',
          hepatitis_b: donor_std.hepatitis_b || '',
          hepatitis_c: donor_std.hepatitis_c || '',
          total: donor_std.total || '',
          status: donor_std.status || '',
          expired: donor_std.expired || false,
          files: donor_std.donor_std_files || []
        },
        semen: {
          collected_on: donor_semen.collected_on || '',
          volume: donor_semen.volume || '',
          concentration: donor_semen.concentration || '',
          total_count: donor_semen.total_count || '',
          motility: donor_semen.motility || '',
          morphology: donor_semen.morphology || '',
          total: donor_semen.total || '---',
          status: donor_semen.status || '',
          expired: donor_semen.expired || false,
          files: donor_semen.donor_semen_files || []
        }
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { std, semen } = this.state;

    let std_status_text = '';
    let std_status_class = '';
    switch (std.status) {
      case 'DRAFT':
        std_status_text = '未提出';
        std_status_class = 'text-red';
        break;
      case 'SUBMITTED':
        std_status_text = '確認中';
        break;
      case 'REMANDED':
        std_status_text = '要確認';
        std_status_class = 'text-red';
        break;
      case 'CERTIFIED':
        if (std.expired) {
          std_status_class = 'text-red';
          std_status_text = '期限切れ';
        } else {
          std_status_text = '登録済';
        }
        break;
    }

    const std_total = std.total;
    let std_total_text = '---';
    let std_total_class = '';
    if (std_total === 'PLUS') {
      std_total_text = '陽性あり';
      std_total_class = 'text-red';
    } else if (std_total === 'MINUS') {
      std_total_text = '陽性なし';
    }

    const std_files = std.files;
    let std_files_text = '';
    let std_files_class = '';
    if (std_files.length === 0) {
      std_files_text = '未添付';
      std_files_class = 'text-red';
    } else {
      std_files_text = '添付済み';
    }

    let semen_status_text = '';
    let semen_status_class = '';
    switch (semen.status) {
      case 'DRAFT':
        semen_status_text = '未提出';
        semen_status_class = 'text-red';
        break;
      case 'SUBMITTED':
        semen_status_text = '確認中';
        break;
      case 'REMANDED':
        semen_status_text = '要確認';
        semen_status_class = 'text-red';
        break;
      case 'CERTIFIED':
        if (semen.expired) {
          semen_status_class = 'text-red';
          semen_status_text = '期限切れ';
        } else {
          semen_status_text = '登録済';
        }
        break;
    }

    const semen_files = semen.files;
    let semen_files_text = '';
    let semen_files_class = '';
    if (semen_files.length === 0) {
      semen_files_text = '未添付';
      semen_files_class = 'text-red';
    } else {
      semen_files_text = '添付済み';
    }

    return (
      <div>
        <Row>
          <Col>
            <Alert variant='warning' className='mypage-alert'>
              <p>
                おすすめの検査キットや検査結果登録の流れは<Link to='/static/to_be_donor'>「精子ドナーになるには」</Link>をご覧ください。
              </p>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col sm={ 12 }>
            <h2 className='mypage-title'>検査結果登録</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row className='d-none d-md-block'>
            <Col className='mypage-std-semen-boxless'>
              <Row className='items-middle'>
                <Col xs={{ offset: 4, span: 2 }} className='mypage-std-semen-item'>
                  <span>結果</span>
                </Col>
                <Col xs={ 2 } className='mypage-std-semen-item'>
                  <span>書類添付</span>
                </Col>
                <Col xs={ 2 } className='mypage-std-semen-item'>
                  <span>ステータス</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-std-semen-box'>
              <Row className='items-middle'>
                <Col xs={ 7 } md={ 4 } className='mypage-std-semen-title'>
                  <span>
                    性病検査
                  </span>
                </Col>
                <Col xs={ 5 } className='mypage-std-semen-item d-md-none'>
                  { std.status !== 'SUBMITTED' &&
                    <Link to='/mypage/std_semen/std' className='btn-ghost-dark btn btn-block'>
                      編集
                    </Link>
                  }
                </Col>
                <Col xs={ 6 } className='mypage-std-semen-item d-md-none'>
                  <span>結果：</span>
                </Col>
                <Col xs={ 6 } md={ 2 } className='mypage-std-semen-item'>
                  <span className={ std_total_class }>{ std_total_text }</span>
                </Col>
                <Col xs={ 6 } className='mypage-std-semen-item d-md-none'>
                  <span>書類添付：</span>
                </Col>
                <Col xs={ 6 } md={ 2 } className='mypage-std-semen-item'>
                  <span className={ std_files_class }>{ std_files_text }</span>
                </Col>
                <Col xs={ 6 } className='mypage-std-semen-item d-md-none'>
                  <span>ステータス：</span>
                </Col>
                <Col xs={ 6 } md={ 2 } className='mypage-std-semen-item'>
                  <span className={ std_status_class }>{ std_status_text }</span>
                </Col>
                <Col md={ 2 } className='mypage-std-semen-item d-none d-md-block'>
                  { std.status !== 'SUBMITTED' &&
                    <Link to='/mypage/std_semen/std' className='btn-ghost-dark btn btn-block'>
                      編集
                    </Link>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-std-semen-box'>
              <Row className='items-middle'>
                <Col xs={ 7 } md={ 4 } className='mypage-std-semen-title'>
                  <span>
                    精液検査
                  </span>
                </Col>
                <Col xs={ 5 } className='mypage-std-semen-item d-md-none'>
                  { semen.status !== 'SUBMITTED' &&
                    <Link to='/mypage/std_semen/semen' className='btn-ghost-dark btn btn-block'>
                      編集
                    </Link>
                  }
                </Col>
                <Col xs={ 6 } className='mypage-std-semen-item d-md-none'>
                  <span>結果：</span>
                </Col>
                <Col xs={ 6 } md={ 2 } className='mypage-std-semen-item'>
                  <span className=''>{ semen.total }</span>
                </Col>
                <Col xs={ 6 } className='mypage-std-semen-item d-md-none'>
                  <span>書類添付：</span>
                </Col>
                <Col xs={ 6 } md={ 2 } className='mypage-std-semen-item'>
                  <span className={ semen_files_class }>{ semen_files_text }</span>
                </Col>
                <Col xs={ 6 } className='mypage-std-semen-item d-md-none'>
                  <span>ステータス：</span>
                </Col>
                <Col xs={ 6 } md={ 2 } className='mypage-std-semen-item'>
                  <span className={ semen_status_class }>{ semen_status_text }</span>
                </Col>
                <Col md={ 2 } className='mypage-std-semen-item d-none d-md-block'>
                  { semen.status !== 'SUBMITTED' &&
                    <Link to='/mypage/std_semen/semen' className='btn-ghost-dark btn btn-block'>
                      編集
                    </Link>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={ 4 }>
              <Link
                to='/mypage/std_semen/submit'
                className='btn-ghost-dark mypage-public-btn btn btn-block'
              >
                提出する
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default DonorStdSemen;
