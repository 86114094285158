import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { ja } from '../../locales/ja';

class ToBeDonor extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='static-contents-wrapper'>
        <Helmet>
          <title>Ton Bébé | 精子ドナーになるには</title>
          <meta name='description' content={ `${ ja['site_description'] }このページではTon Bébéに精子ドナーとして登録をする方法を説明します。` } />
        </Helmet>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>ドナー登録について</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>新規登録画面より登録してください。</p>
            <p className='static-contents-paragraph'>Ton Bébéは妊娠を望む女性により多くの選択肢を提供し、より良い判断を支援するために精子ドナーおよび精子提供ボランティの登録を広く受け付けています。</p>
            <p className='static-contents-paragraph'>以下の条件に当てはまる男性であればどなたでも登録可能です。
              <ul>
                <li>心身ともに健康で、性病などの罹患がない方</li>
                <li>遺伝病等の子供への影響があるとされる病気がない方</li>
                <li>精子提供後に女性へ自らコンタクトをしないとお約束できる方</li>
                <li>安易な性行為や金銭目的ではなく、妊娠を望む女性を心から支援できる方</li>
                <li>一般的な言動やマナーに気を使い、人とのコミュニケーションがとれる方</li>
              </ul>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>性病/精液検査結果の登録について</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>提供を受ける女性が安心してドナーを探せるよう、ドナーの方には性病検査および精液検査の結果をご登録いただくようお願いしております。<br/>ご登録の際に検査結果書類の画像（検査結果証もしくはウェブ画面のスクリーンショット）を合わせてご提出いただき、当サービス運営事務局にて内容を確認いたします。<br/>確認が取れた後、女性ユーザー様が検査結果の値を閲覧できるようになります。<br/>（書類の画像が公開されることはありません。）</p>
            <p className='static-contents-paragraph'>当サービスに入会金はありませんが、検査結果の確認手数料として性病・精液検査それぞれ3,000円ずつ（両方同時の場合は5,000円）をお支払いただいております。検査結果が正当であると弊社にて審査の後、ドナープロフィールの一部として検査結果が表示されます。</p>
            <p className='static-contents-paragraph'>検査結果の有効性は検査日から１年としており、１年以内に再検査の上、再度提出をお願いします。再提出には改めて上記料金が発生します。期限が切れた場合は、検査結果が表示されなくなりますのでご注意ください。</p>
            <p className='static-contents-paragraph'>検査結果の提出なしでも当サービスの利用は可能ですが、女性により安心してご指名いただくために提出をお勧めします。</p>
            <p className='static-contents-paragraph'>おすすめの【性病検査キット】 => <a href="//af.moshimo.com/af/c/click?a_id=1784748&amp;p_id=54&amp;pc_id=54&amp;pl_id=616&amp;url=https%3A%2F%2Fitem.rakuten.co.jp%2Fstd-checker%2Ftype_em%2F&amp;m=http%3A%2F%2Fm.rakuten.co.jp%2Fstd-checker%2Fi%2F10000007%2F" rel="nofollow" referrerpolicy="no-referrer-when-downgrade"><img src="//thumbnail.image.rakuten.co.jp/@0_gold/std-checker/img/type_Em_01.png?_ex=128x128" alt="" /></a><img src="//i.moshimo.com/af/i/impression?a_id=1784748&amp;p_id=54&amp;pc_id=54&amp;pl_id=616" alt="" width="1" height="1" /></p>
            <p className='static-contents-paragraph'>おすすめの【精液検査キット】 => <a href="//af.moshimo.com/af/c/click?a_id=1784748&amp;p_id=54&amp;pc_id=54&amp;pl_id=616&amp;url=https%3A%2F%2Fitem.rakuten.co.jp%2Fonlinekensaoes%2F0001%2F&amp;m=http%3A%2F%2Fm.rakuten.co.jp%2Fonlinekensaoes%2Fi%2F10000000%2F" rel="nofollow" referrerpolicy="no-referrer-when-downgrade"><img src="//thumbnail.image.rakuten.co.jp/@0_mall/onlinekensaoes/cabinet/imgrc0071360275.jpg?_ex=128x128" alt="" /></a><img src="//i.moshimo.com/af/i/impression?a_id=1784748&amp;p_id=54&amp;pc_id=54&amp;pl_id=616" alt="" width="1" height="1" /></p>
            <p className='static-contents-paragraph'>＊どちらもTon Bébéの入力必須項目を満たしております。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>検査結果公開までの流れ</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>ドナー一覧へプロフィールと検査結果が公開されるまでの手順は以下の通りです。
              <ol>
                <li>新規登録</li>
                <li>プロフィール入力・公開 → ドナー一覧に表示</li>
                <li>病気検査結果・精液検査結果の入力・書類の提出</li>
                <li>運営事務局にて確認</li>
                <li>提出内容に不備がなければ承認 → 性病有無・精液検査結果公開</li>
              </ol>
            </p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default ToBeDonor;
