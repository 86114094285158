import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Button,
  Modal,
  Image
} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { userTypes } from '../../constants';
import {
  FacebookIcon,
  TwitterIcon
} from 'react-share';

class SignupModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { name } = this.props.formData;
    const { provider } = this.props;

    const title = this.props.userType === userTypes.DONEE ? 'ドナーを探す' : 'ドナーになる';

    let account_info = null;
    if (provider == 'email') {
      account_info =
        <div>
          <Row className='signup-modal-item-row'>
            <Col sm={{ span: 3, offset: 3 }} className='signup-modal-item-h'>
              イニシャル
            </Col>
            <Col sm={{ span: 6 }} className='signup-modal-item-d'>
              { name ? name.split('').join('.').concat('.') : '' }
            </Col>
          </Row>
          <Row className='signup-modal-item-row'>
            <Col sm={{ span: 3, offset: 3 }} className='signup-modal-item-h'>
              Email
            </Col>
            <Col sm={{ span: 6 }} className='signup-modal-item-d'>
              { this.props.formData.email }
            </Col>
          </Row>
        </div>
    } else {
      let  sns_icon, sns_name = null;
      if (provider == 'facebook') {
        sns_icon = <FacebookIcon size={ 40 } round />
        sns_name = 'Facebook';
      } else if (provider == 'twitter') {
        sns_icon = <TwitterIcon size={ 40 } round />
        sns_name = 'Twitter';
      }
      account_info =
        <div>
          <Row className='signup-modal-item-row'>
            <Col xs={{ span: 2, offset: 2 }} className='signup-modal-sns-logo'>
              { sns_icon }
            </Col>
            <Col xs={{ span: 8 }} className='signup-modal-item-d items-middle'>
              <span>{ sns_name } で登録</span>
            </Col>
          </Row>
          <Row className='signup-modal-item-row'>
            <Col sm={{ span: 10, offset: 1 }} className='signup-modal-sns-logo'>
              <span className='sns-note'>※あなたがTon Bébéに登録したことを友人に知られたり、メッセージが投稿されることはありません。</span>
            </Col>
          </Row>
        </div>
    }

    let msg = '';
    if (this.props.userType === userTypes.DONEE) {
      msg= <span>私は <span className='text-pink font-weight-bold'>女性</span> で精子提供をする <span className='text-primary font-weight-bold'>男性ドナー</span> を探しています。</span>
    } else {
      msg= <span>私は <span className='text-blue font-weight-bold'>男性</span> で <span className='text-primary font-weight-bold'>精子提供ドナー</span> として登録します。</span>
    }

    const btn_title = this.props.userType === userTypes.DONEE ? '男性' : '女性';

    return (
      <Modal
        className='signup-modal'
        show={ this.props.show }
        onHide={ this.props.handleHideModal }
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { account_info }
          <Row className=''>
            <Col sm={{ span: 12 }}>
              <p className='text-center'>{ msg }</p>
            </Col>
          </Row>
          <Row className='signup-modal-item-row'>
            <Col sm={{ span: 5, offset: 1 }}>
              <Button
                variant=''
                className='btn-ghost-dark'
                onClick={ this.props.handleSignup }
                block
              >
                はい
              </Button>
            </Col>
            <Col sm={{ span: 5, offset: 0 }}>
              <Button
                variant=''
                className='btn-ghost-dark'
                onClick={ this.props.switchUserType }
                block
              >
                いいえ({ btn_title }の方)
              </Button>
            </Col>
          </Row>
          <Row className=''>
            <Col sm={{ span: 12 }}>
              <p className='text-center'>
                <a href='/static/terms' target='_blank' className='btn-link-dark'>利用規約</a> に同意の上、新規登録してください。
              </p>
              <p className='text-center'>
                ＊18歳未満の方はご利用になれません。
              </p>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Link to='/login' className='btn-link-dark'>ログインはこちら</Link>
        </Modal.Footer>
      </Modal>
    )
  }
}

SignupModal.propTypes = {
  show: PropTypes.bool.isRequired,
  provider: PropTypes.string.isRequired,
  formData: PropTypes.object.isRequired,
  userType: PropTypes.string.isRequired,
  handleHideModal: PropTypes.func.isRequired,
  handleSignup: PropTypes.func.isRequired,
  switchUserType: PropTypes.func.isRequired
}

export default SignupModal;
