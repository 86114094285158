import React from 'react';
import {
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import { Spinner } from '../../common/spinner';

class Settings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false
    }
  }

  handleLogout = (e) => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    this.props.history.push('/');
  }

  render() {
    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col sm={ 12 }>
            <h2 className='admin-title'>ログアウト</h2>
          </Col>
        </Row>
        <div className='admin-content-wrapper'>
          <Row>
            <Col xs={{ span: 4, offset: 1 }} className='admin-submit'>
              <Button
                className='btn-ghost-dark'
                onClick={ this.handleLogout }
                block
              >
                ログアウト
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default withRouter(Settings);
