import React from 'react';
import PropTypes from 'prop-types';
import {
  ListGroup,
  DropdownButton,
  Dropdown,
  Badge
} from 'react-bootstrap'
import ApiClient from '../../services/api_client';
import Auth from '../../services/auth';

class ConvList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      convs: [],
      unread_convs: [],
      item_idx: 0
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/convs`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Convs] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const res_convs = json.data.convs;
      const res_unread_conves = json.data.unread_convs;

      const convs = res_convs.map(conv => {
        const pal = Auth.isDonor() ? conv.donee : conv.donor;
        return {
          conv_id: conv.id + '',
          user_no: pal.user_no,
          name: pal.name
        }
      })

      this.setState({
        convs: convs,
        unread_convs: res_unread_conves
      })

      this.props.handleConvSelect(convs.length > 0 ? convs[0].conv_id : '');
    }).catch((err) => {
      console.error(err);
    });
  }

  handleConvSelect = (e) => {
    const conv_id = e.currentTarget.dataset.conv_id;

    const idx = this.state.unread_convs.indexOf(conv_id * 1);
    let unread_convs = this.state.unread_convs;
    unread_convs.splice(idx, 1);

    const item_idx = e.currentTarget.dataset.item_idx;

    this.setState({
      unread_convs: unread_convs,
      item_idx: item_idx
    });

    this.props.handleConvSelect(conv_id);
  }

  render() {
    const { convs, unread_convs, item_idx } = this.state;

    let convSpans = [];
    let convList = [];
    let convDDItems = [];
    for (let i in convs) {
      const conv = convs[i];
      const conv_span =
          <span key={ i }>
            { conv.name }
            <small>
              &nbsp;
              (No. { conv.user_no })
              &nbsp;
              { unread_convs.indexOf(conv.conv_id * 1) > -1 && <Badge variant="secondary">新着</Badge> }
            </small>
          </span>;

      convSpans.push(conv_span);

      convList.push(
        <ListGroup.Item
          key={ i }
          onClick={ this.handleConvSelect }
          data-conv_id={ conv.conv_id }
          data-item_idx={ i }
          active={ conv.conv_id === this.props.active_conv_id }
        >
          { conv_span }
        </ListGroup.Item>
      );

      if (i > 0) { convDDItems.push(<Dropdown.Divider key={ i + 999 } />)}

      convDDItems.push(
        <Dropdown.Item
          key={ i }
          onClick={ this.handleConvSelect }
          data-conv_id={ conv.conv_id }
          data-item_idx={ i }
        >
          { conv_span }
        </Dropdown.Item>
      );
    }

    return (
      <div className='message-conv-list-wrapper'>
        <ListGroup className='message-conv-list-box d-none d-md-block'>
          { convList }
        </ListGroup>
        <DropdownButton
          id='conv-title'
          title={ convSpans[item_idx] || '---' }
          size='lg'
          className='d-block d-md-none'
        >
          { convDDItems }
        </DropdownButton>
      </div>
    )
  }
}

ConvList.propTypes = {
  active_conv_id: PropTypes.string,
  handleConvSelect: PropTypes.func
}

export default ConvList;
