import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
} from 'react-bootstrap'
import { withRouter } from 'react-router';

class AdminMenus extends React.Component {
  constructor(props) {
    super(props);
  }

  handleMenuSelect = (key) => {
    this.props.history.push('/admin/' + key);
  }

  render() {
    const { activeMenu } = this.props;

    return (
      <Nav className='flex-column admin-nav'
        activeKey={ activeMenu }
        onSelect={ this.handleMenuSelect }
      >
        <Nav.Link className='admin-nav-item' eventKey='std_approval'>性病検査結果承認</Nav.Link>
        <Nav.Link className='admin-nav-item' eventKey='semen_approval'>精子検査結果承認</Nav.Link>
        <Nav.Link className='admin-nav-item' eventKey='settings'>設定</Nav.Link>
      </Nav>
    )
  }
}

AdminMenus.propTypes = {
  activeMenu: PropTypes.string.isRequired
}

export default withRouter(AdminMenus);
