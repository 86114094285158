import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap'
import ApiClient from '../../services/api_client';
import { toast } from 'react-toastify';
import { Spinner } from '../common/spinner';

class Message extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      message: ''
    }
  }

  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value
    })
  }

  handleSendMessage = (e) => {
    const { message } = this.state;
    if (message.length === 0) return;

    this.setState({ loading: true });

    const { donor_no } = this.props;

    const data = {
      donor_no: donor_no,
      message_body: message
    }

    let req = ApiClient.securePostRequestTo(
      `/api/v1/convs`,
      data
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Create conv] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success('送信しました。');
      this.setState({ message: '' });
    }).catch((err) => {
      toast.error('送信に失敗しました。時間をおいてお試しください。');
    });
  }

  render() {
    return (
      <Container className='profile-content-wrapper'>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col className='profile-message-box'>
            <Row>
              <Col xs={ 12 } className='profile-content-title'>
                <span>このドナーにメッセージを送る</span>
              </Col>
            </Row>
            <Row>
              <Col xs={ 12 }>
                <Form.Group>
                  <Form.Control
                    as='textarea'
                    placeholder='初めまして。M.T.と申します。&#13;&#10;A型で健康的なドナーを探しております。&#13;&#10;まずは面談などのお話をする機会を持たせていただけますでしょうか。'
                    value={ this.state.message }
                    onChange={ this.handleMessageChange }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 4, offset: 4 }}>
                <Button
                  variant=''
                  className='btn-ghost-dark'
                  onClick={ this.handleSendMessage }
                  disabled={ this.state.message.length === 0 }
                  block
                >送信</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

Message.propTypes = {
  donor_no: PropTypes.string
}

export default Message;
