import React from 'react';
import { Helmet } from 'react-helmet';
import Header from './header';
import ForgotForm from './forgot_form';
import ResetForm from './reset_form';
import { ja } from '../../locales/ja';

class Password extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { action, token } = this.props.match.params;

    let form = null;
    if (action == 'forgot') {
      form = <ForgotForm />
    } else if (action == 'reset') {
      form = <ResetForm token={ token } />
    }

    return (
      <div>
        <Helmet>
          <title>Ton Bébé | パスワードリセット</title>
          <meta name='description' content={ `${ ja['site_description'] }このページでパスワードのリセットができます。`} />
        </Helmet>
        <Header />
        { form }
      </div>
    )
  }
}

export default Password;
