import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const now = new Date();

    return (
      <Container className='footer-wrapper'>
        <Row as='footer'>
          <Col md={ 12 } lg={ 2 } className='footer-link'>
            <Link to='/static/terms'>利用規約</Link>
          </Col>
          <Col md={ 6 } lg={ 3 } className='footer-link'>
            <Link to='/static/privacy'>プライバシーポリシー</Link>
          </Col>
          <Col md={ 6 } lg={ 3 } className='footer-link'>
            <Link to='/static/commerce'>特定商取引法に基づく表記</Link>
          </Col>
          <Col md={ 6 } lg={ 2 } className='footer-link'>
            <a href='https://blog.tonbebe.jp'>ブログ</a>
          </Col>
          <Col md={ 6 } lg={ 2 } className='footer-link'>
            <Link to='/static/help'>ヘルプ</Link>
          </Col>
        </Row>
          <Col sm={ 12 } className='copyright'>
            <span to='/'>&copy;2019-{ now.getFullYear() }&nbsp;&nbsp;Ton Bébé</span>
          </Col>
        <Row>
        </Row>
      </Container>
    )
  }
}

export default Footer;
