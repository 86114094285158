import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap'

class SignupConfirmContents extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let mailLink = <div></div>;
    const email = this.props.email;
    if (/^\w+([-+.]\w+)*@gmail.com$/.test(email)) {
      mailLink =
        <Button className='btn-ghost-dark' href='https://www.google.com/gmail/' bsSize='large'>
          Gmailを開く
        </Button>
    }

    return (
      <Container className='signup-confirm-contents-wrapper'>
        <Row>
          <Col xsOffset={ 1 } xs={ 10 }>
            <p className='signup-confirm-contents-paragraph'>Ton Bébéへようこそ</p>
            <p className='signup-confirm-contents-paragraph'>ご登録いただいたメールアドレス({ email })に仮登録完了のメールをお送りしました。<br/>メールに記載のURLにアクセスしていただき、本登録を完了してください。<br/>※URLの有効期限は24時間です。</p>
            <p className='signup-confirm-contents-paragraph'>しばらく経ってもメールが届かない場合、もしくは24時間以上経過した場合は、
      再登録をお願いいたします。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 } className='text-center'>
            { mailLink }
          </Col>
        </Row>
      </Container>
    )
  }
}

SignupConfirmContents.propTypes = {
  email: PropTypes.string
}

export default SignupConfirmContents;
