import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Util from '../../services/util';

class ResultRow extends React.Component {
  constructor(props) {
    super(props);
  }

  handleDonorSelect = (e) => {
    const user_no = e.currentTarget.dataset.user_no;
    this.props.history.push('/donor/' + user_no);
  }

  render() {
    const { donor } = this.props;

    let active_in_hour = donor.active_in_hour
    let active_in = '';
    if (active_in_hour <= 24) {
      active_in = '24時間以内';
    } else if (active_in_hour <= 24 * 3) {
      active_in = '3日以内';
    } else if (active_in_hour <= 24 * 7) {
      active_in = '1週間以内';
    } else if (active_in_hour <= 24 * 14) {
      active_in = '2週間以内';
    } else if (active_in_hour <= 24 * 31) {
      active_in = '1ヶ月以内';
    } else if (active_in_hour <= 24 * 93) {
      active_in = '3ヶ月以内';
    } else {
      active_in = '3ヶ月以上';
    }

    let areas = donor.areas;
    let areas_html = '';
    for (let i = 0; i < areas.length; i++) {
      if (i > 0) { areas_html += '<br/>' }
      areas_html += Util.val('area', areas[i]);
      if (i == 1 && 3 < areas.length) {
        areas_html += `<br/>+他${ areas.length - 2 }県`;
        break;
      } else if (i > 2) {
        break;
      }
    }

    let children_html = (donor.son + donor.daughter) > 0 ? '子あり' : '子なし'

    let methods = donor.methods;
    let methods_html = '';
    for (let i = 0; i < methods.length; i++) {
      if (i > 0) { methods_html += '<br/>' }
      methods_html += Util.val('methods', methods[i]);
    }

    let std_html = donor.std_certified ? '◎' : '---'
    let semen_html = donor.semen_total != '' ? donor.semen_total : '---'

    return (
      <tr
        className='result-row'
        data-user_no={ donor.user_no }
        onClick={ this.handleDonorSelect }
      >
        <td className='text-center'>
          { donor.name.split('').join('.') + '.' }<br/>{ active_in }
        </td>
        <td className='d-none d-md-table-cell' dangerouslySetInnerHTML={{ __html: areas_html }} />
        <td className='d-none d-md-table-cell'>{ donor.age } 歳<br/>{ donor.blood_type } 型</td>
        <td className='d-none d-md-table-cell'>
          { donor.height } cm<br/>
          { donor.weight } kg<br/>
          { Util.val('body_figure', donor.body_figure) }
        </td>
        <td className='d-none d-md-table-cell'>{ Util.val('college_type', donor.college_type) }<br/>{ donor.major.substr(0, 15) }</td>
        <td className='d-none d-md-table-cell'>{ Util.val('marriage', donor.marriage) }<br/>{ children_html }</td>
        <td className='d-none d-md-table-cell'>【性病検査】{ std_html }<br/>【精子品質】{ semen_html }</td>
        <td className='d-none d-md-table-cell' dangerouslySetInnerHTML={{ __html: methods_html }} />

        { /* For only xs,sm */ }
        <td className='d-block d-md-none '>
          { donor.age } 歳 / { donor.blood_type } 型<br/>
          { donor.height } cm / { donor.weight } kg<br/>
          【性病検査】{ std_html }<br/>
          【精子品質】{ semen_html }<br/>
          <span dangerouslySetInnerHTML={{ __html: areas_html.replace(/<br\/>/g, '・').replace('+', '') }} />
        </td>
      </tr>
    )
  }
}

ResultRow.propTypes = {
  donor: PropTypes.object
}

export default withRouter(ResultRow);
