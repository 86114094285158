import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
} from 'react-bootstrap'
import { withRouter } from 'react-router';

class DonorMenus extends React.Component {
  constructor(props) {
    super(props);
  }

  handleMenuSelect = (key) => {
    this.props.history.push('/mypage/' + key);
  }

  render() {
    const { activeMenu } = this.props;

    return (
      <Nav className='flex-column mypage-nav'
        activeKey={ activeMenu }
        onSelect={ this.handleMenuSelect }
      >
        <Nav.Link className='mypage-nav-item' eventKey='profile'>プロフィール</Nav.Link>
        <Nav.Link className='mypage-nav-item' eventKey='account'>アカウント</Nav.Link>
        <Nav.Link className='mypage-nav-item' eventKey='std_semen'>検査結果登録</Nav.Link>
        <Nav.Link className='mypage-nav-item' eventKey='record'>提供実績登録</Nav.Link>
      </Nav>
    )
  }
}

DonorMenus.propTypes = {
  activeMenu: PropTypes.string
}

export default withRouter(DonorMenus);
