import React from 'react';
import {
  Row,
  Col,
  Button,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import Util from '../../../services/util';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner';

class StdApproval extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stds: [],
      loading: false
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/admin/std_approvals`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET StdApprovals] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      let stds = json.data.map(std => {
        const updated_at = new Date(std.updated_at)
        return {
          id: std.id,
          user_no: std.user_id + 500,
          total: std.total,
          status: std.status,
          updated_at: updated_at.toLocaleString()
        }
      });
      this.setState({
        stds: stds
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { stds } = this.state;

    let applicationList = [];
    for (let i in stds) {
      console.log(stds[i])
      applicationList.push(
        <tr key={i}>
          <th>{ stds[i].user_no }</th>
          <th>{ Util.val('pos_neg', stds[i].total) }</th>
          <th>{ Util.val('std_status', stds[i].status) }</th>
          <th>{ stds[i].updated_at }</th>
          <th>
            <Link 
              to={ `/admin/std_approval/${ stds[i].id }/edit` }
              className='btn btn-sm btn-ghost-dark'
            >
              詳細
            </Link>
          </th>
        </tr>
      );
    }

    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col sm={ 12 }>
            <h2 className='admin-title'>性病検査結果承認</h2>
          </Col>
        </Row>
        <div className='admin-content-wrapper'>
          <Row>
            <Col xs={ 12 } className='admin-applications'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ユーザーNO</th>
                    <th>結果</th>
                    <th>ステータス</th>
                    <th>提出日</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { applicationList }
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default StdApproval;
