import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { ja } from '../../locales/ja';

class DonorManners extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='static-contents-wrapper'>
        <Helmet>
          <title>Ton Bébé | ドナーの心得</title>
          <meta name='description' content={ `${ ja['site_description'] }このページではTon Bébéに登録しているドナーとしての心得を説明します。` } />
        </Helmet>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>精子提供を希望する方の置かれている状況は様々です。<br/>子供が欲しくても持てない。そんな希望者の思いを最大限に尊重し、敬意を持って接しましょう。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>一、安易な性行為や金銭目的での提供はしないこと</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>あなたにとっては一回の射精でも、提供を受ける女性にとっては一生に関わる問題です。<br/>安易な性行為や金銭目的の提供は絶対にしないでください。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>一、尊厳を持って接すること</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>相手は一人の女性です。場合によってはあなたの遺伝子を持った子供を育てることになる女性です。<br/>「子供が欲しい」と願う相手の気持ちに寄り添い、尊厳を持って接しましょう。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>一、自分からコンタクトを取らないこと</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>女性は今後の人生においてあなたと関わることがない前提で提供を受けている可能性があります。<br/>トラブルの原因にもなりますので、女性から希望されない限り、自分からコンタクトを取ることは避けましょう。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>一、健康に気をつけること</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>精子は健康状態の影響を大きく受けます。検査結果が優秀でも健康状態によっては量・質ともに低下します。<br/>特に提供を控えた数日間は健康に気をつけるようにしましょう。</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default DonorManners;
