import React from 'react';
import { Helmet } from 'react-helmet';
import LoginHeader from './login_header';
import LoginForm from './login_form';
import { ja } from '../../locales/ja';

class Login extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Ton Bébé | ログイン</title>
          <meta name='description' content={ `${ ja['site_description'] }このページからTon Bébéにログインしてください。` } />
        </Helmet>
        <LoginHeader />
        <LoginForm />
      </div>
    )
  }
}

export default Login;
