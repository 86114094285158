import React from 'react';
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import { FormCheckGroup } from '../common/custom_form_components';
import { ja } from '../../locales/ja';

class Conditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      show_conditions: false
    }
  }

  toggleConditionsBox = () => {
    this.setState({ show_conditions: !this.state.show_conditions });
  }

  render() {
    const { handleCondChecked, conditions } = this.props;

    const dClass = this.state.show_conditions ? '' : 'd-none';
    const toggleBtnText = this.state.show_conditions ? '閉じる' : '条件指定';

    return (
      <Container className='conditions-wrapper'>
        <Row className=''>
          <Col sm={{ span: 4, offset: 4 }} xs={{ span: 6, offset: 3 }}>
            <Button
              className='btn-ghost-dark'
              size='md'
              variant='light'
              onClick={ this.toggleConditionsBox }
              block
            >
              { toggleBtnText }
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className={ `conditions-box ${ dClass }` }>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>エリア</span>
              </Col>
              <Col md={ 10 } xs={ 12 }className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['area'] }
                  targetData={{
                    HOKKAIDO: '北海道',
                    TOHOKU: '東北',
                    KANTO: '関東',
                    CHUBU: '中部',
                    KINKI: '近畿',
                    CHUGOKU: '中国',
                    SHIKOKU: '四国',
                    KYUSHU: '九州'
                  }}
                  dataType='area'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>年齢</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['age'] }
                  targetData={{
                    20: '20代',
                    30: '30代',
                    40: '40代',
                    50: '50代以上'
                  }}
                  dataType='age'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>血液型</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['blood_type'] }
                  targetData={{
                    A: 'A',
                    B: 'B',
                    O: 'O',
                    AB: 'AB'
                  }}
                  dataType='blood_type'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>身長(cm)</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['height'] }
                  targetData={{
                    150: '~160',
                    160: '160~170',
                    170: '170~180',
                    180: '180~'
                  }}
                  dataType='height'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>体型</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['body_figure'] }
                  targetData={ ja['body_figure'] }
                  dataType='body_figure'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>学歴</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['college_type'] }
                  targetData={ ja['college_type'] }
                  dataType='college_type'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 10, offset: 2 }} xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['major_type'] }
                  targetData={ ja['major_type'] }
                  dataType='major_type'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>婚姻歴</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['marriage'] }
                  targetData={ ja['marriage'] }
                  dataType='marriage'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>子供</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['children'] }
                  targetData={{
                    TRUE: 'あり',
                    FALSE: 'なし'
                  }}
                  dataType='children'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>性病検査</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['std_certified_only'] }
                  targetData={{ TRUE: '検査済み' }}
                  dataType='std_certified_only'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>精子品質</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['semen_total'] }
                  targetData={{
                    A: 'A',
                    B: 'B',
                    C: 'C',
                    D: 'D',
                    E: 'E'
                  }}
                  dataType='semen_total'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>提供方法</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['methods'] }
                  targetData={ ja['methods'] }
                  dataType='methods'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row>
              <Col md={ 2 } xs={ 12 } className='condition-item-h'>
                <span>謝礼</span>
              </Col>
              <Col md={ 10 } xs={ 12 } className='condition-item-d'>
                <FormCheckGroup
                  checkedIds={ conditions['gratuity'] }
                  targetData={{
                    0: 'なし',
                    5000: '~5,000円',
                    10000: '~10,000円'
                  }}
                  dataType='gratuity'
                  handleChecked={ handleCondChecked }
                />
              </Col>
            </Row>
            <Row className='search-button'>
              <Col md={{ span: 3, offset: 2 }} sm={{ span: 4, offset: 1 }} xs={ 6 }>
                <Button
                  className='btn-ghost-dark'
                  size='lg'
                  variant='light'
                  onClick={ this.props.handleClearCondClick }
                  block
                >
                  クリア
                </Button>
              </Col>
              <Col md={{ span: 3, offset: 2 }} sm={{ span: 4, offset: 2 }} xs={ 6 }>
                <Button
                  className='btn-ghost-dark'
                  size='lg'
                  variant='light'
                  onClick={ this.props.handleSearchClick }
                  block
                >
                  検索
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Conditions;
