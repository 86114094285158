import React from 'react';
import PropTypes from 'prop-types';
import {
  Form
} from 'react-bootstrap';

class FormCheckGroup extends React.Component {
  render() {
    const { checkedIds, targetData, dataType, handleChecked } = this.props;

    let checkBoxes = [];
    for (let key in targetData) {
      checkBoxes.push(
        <Form.Check
          inline
          checked={ checkedIds.indexOf(key) >= 0 }
          label={ targetData[key] }
          id={ dataType + '-' + key }
          key={ key }
          data-key={ key }
          data-type={ dataType }
          onChange={ handleChecked }
        />
      );
    }

    return (
      <Form>
        { checkBoxes }
      </Form>
    )
  }
}

FormCheckGroup.propTypes = {
  checkedIds: PropTypes.array,
  targetData: PropTypes.object,
  handleChecked: PropTypes.func
}

class NumSelectGroup extends React.Component {
  render() {
    const { id, value, from, to, handleSelectChange } = this.props;

    let options = [];
    for (let i = from; i <= to; i++) {
      options.push(
        <option value={ i }>{ i }</option>
      );
    }

    return (
     <Form.Control
       componentClass='select'
       id={ id }
       value={ value }
       onChange={ handleSelectChange }
     >
      { options }
     </Form.Control>
    )
  }
}

NumSelectGroup.propTypes = {
  id: PropTypes.number,
  value: PropTypes.number,
  from: PropTypes.number,
  to: PropTypes.number,
  handleSelectChange: PropTypes.func
}

export {
  FormCheckGroup,
  NumSelectGroup
}
