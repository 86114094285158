import React from 'react';
import {
  Row,
  Col,
  Button,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Util from '../../../services/util';
import { toast } from 'react-toastify';

class ApproveRecord extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      remaining: false,
      records: []
    }
  }

  componentWillMount = () => {
    this.execLoadData();
  }

  execLoadData = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donor_records`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET donor_records] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      let pending_cnt = 0;
      const records = json.data.map(record => {
        if (record.status === 'PENDING') pending_cnt += 1;
        return {
          id: record.id,
          donor: record.donor.name,
          date: record.donated_at,
          area: Util.val('area', record.area),
          method: Util.val('methods', record.meth),
          status: record.status
        }
      })
      this.setState({
        remaining: pending_cnt > 0,
        records: records
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  handleActionBtnClick = (e) => {
    const record_id = e.currentTarget.dataset.record_id;
    const status = e.currentTarget.dataset.status;

    this.execUpdateStatus(record_id, status);
  }

  execUpdateStatus = (id, status) => {
    let req = ApiClient.securePostRequestTo(
      `/api/v1/donor_records/update_status`,
      { id: id, status: status }
    );

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[Update record status] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      this.execLoadData();
      toast.success('更新しました。');
    }).catch((err) => {
      toast.error('更新に失敗しました。時間をおいてお試しください。');
    });
  }

  render() {
    const { remaining, records } = this.state;

    let remaining_msg_cls = remaining ? 'remaining-msg' : 'no-remaining';

    let recordList = [];
    for (let i in records) {
      const record = records[i];
      let action_td_inner;
      if (record.status === 'PENDING') {
        action_td_inner =
        <span>
          <Button
            className='btn-ghost-dark btn-xs'
            data-record_id={ record.id }
            data-status='APPROVED'
            onClick={ this.handleActionBtnClick }
          >承認</Button>
          &nbsp;
          <Button
            className='btn-ghost-dark btn-xs'
            data-record_id={ record.id }
            data-status='REJECTED'
            onClick={ this.handleActionBtnClick }
          >拒否</Button>
        </span>
      } else {
        action_td_inner = '【' + Util.val('record_status', record.status) + '】';
      }
      recordList.push(
        <tr key={ i }>
          <td>{ record.donor }</td>
          <td className='d-none d-md-table-cell'>{ record.date }</td>
          <td className='d-none d-md-table-cell'>{ record.area }</td>
          <td className='d-none d-md-table-cell'>{ record.method }</td>
          <td className='mypage-actions d-none d-md-table-cell'>{ action_td_inner }</td>
          <td className='d-md-none'>
            日付： { record.date }<br/>
            エリア： { record.area }<br/>
            提供方法： { record.method }<br/>
            { action_td_inner }
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Row>
          <Col>
            <h2 className='mypage-title'>提供実績承認</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col className='mypage-item-group'>
              <Row className='items-middle'>
                <Col className=''>
                  <p className={ remaining_msg_cls }>＊未承認の実績があります。</p>
                  <p>＊承認するとドナーの提供実績の回数に反映されます。ドナーの今後の活動のために、是非とも承認をしてあげましょう。</p>
                  <p>＊あなたへの提供であることが公開されることはありません。</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='record-table-wrapper'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>ドナー</th>
                    <th>日付</th>
                    <th>エリア</th>
                    <th>提供方法</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  { recordList }
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default ApproveRecord;
