import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import Util from '../../services/util';

class RequestInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { donor } = this.props;

    let methods = donor.methods;
    let methods_html = '';
    for (let i = 0; i < methods.length; i++) {
      if (i > 0) { methods_html += '・' }
      methods_html += Util.val('methods', methods[i]);
    }

    return (
      <Container className='profile-content-wrapper'>
        <Row>
          <Col className='profile-content-box'>
            <Row>
              <Col md={ 12 } className='profile-content-title'>
                <span>精子提供について</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>提供方法</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ methods_html }</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>希望謝礼金</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ Util.thousandSeparate(donor.gratuity) } 円</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

RequestInfo.propTypes = {
  donor: PropTypes.object
}

export default RequestInfo;
