import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Recommends from './recommends';

// Donee interview
const BLOG_1_URL = 'https://blog.tonbebe.jp/posts/18854795';
const BLOG_1_IMG_URL = 'https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/1122428/55d16e390e011b64635a46baa124371a_fd59ce98f8ea5aab53515631bacc7d52.png';
const BLOG_1_DATE = '2021.07.20';

// Donor interview 1
const BLOG_2_URL = 'https://blog.tonbebe.jp/posts/42393098';
const BLOG_2_IMG_URL = 'https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/1122428/fe9cfa18526b7b5da1bc9013686ad15a_db23a8a97cfef104c088a9cdd20c6648.png?width=1152';
const BLOG_2_DATE = '2023.3.27';
const BLOG_2_TITLE = '全ての女性に子どもを授かることができるチャンスを';
const BLOG_2_BODY = '皆さん、お久しぶりです！Ton Bébé運営の鷲﨑です。今回のTon Bébéブログでは、実際の精子提供ドナーでいらっしゃるH.S.様にお話を伺いました！';

// Donor interview 2
const BLOG_3_URL = 'https://blog.tonbebe.jp/posts/37811762';
const BLOG_3_IMG_URL = 'https://cdn.amebaowndme.com/madrid-prd/madrid-web/images/sites/1122428/e04933ef6772332f7f3a0d9fa9bec229_60eeb6ed2341f4f6292b5f7acca9da9e.png?width=1152';
const BLOG_3_DATE = '2022.9.29';
const BLOG_3_TITLE = '福祉の仕事経験を生かして女性に寄り添ったサポートを';
const BLOG_3_BODY = '皆さん、こんにちは！Ton Bébé運営の鷲﨑です。今回のTon Bébéブログでは、実際の精子提供ドナーでいらっしゃるK.H.様にお話を伺いました！';

class HomeContents extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Recommends />
        <Container className='home-contents-wrapper'>
          <Row>
            <Col sm={ 12 }>
              <h2 className='home-contents-title'>人気の検索</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } className='text-center'>
              <Link to='/search?height=170,180' className='popular-search-button btn-ghost-dark btn'>
                身長170cm以上
              </Link>
              <Link to='/search?age=30,40' className='popular-search-button btn-ghost-dark btn'>
                30〜40代
              </Link>
              <Link to='/search?college_type=PUBLIC,PRIVATE' className='popular-search-button btn-ghost-dark btn'>
                大卒
              </Link>
              <Link to='/search?std_certified_only=TRUE' className='popular-search-button btn-ghost-dark btn'>
                性病検査済み
              </Link>
              <Link to='/search?methods=TIMING' className='popular-search-button btn-ghost-dark btn'>
                タイミング法
              </Link>
              <Link to='/search?gratuity=0' className='popular-search-button btn-ghost-dark btn'>
                謝礼不要
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className='home-contents-wrapper'>
          <Row>
            <Col sm={ 12 }>
              <h2 className='home-contents-title'>Ton Bébéブログ</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <h3 className='home-contents-paragraph'>Ton Bébéブログではユーザーインタビューを多数掲載しています。ぜひご覧ください！！</h3>
            </Col>
          </Row>
          <Row className='home-contents-blog-wrapper'>
            <Col sm={ 6 } className=''>
              <h4 className='home-contents-paragraph'>ドニー（女性）インタビュー</h4>
              <a href={ BLOG_1_URL }>
                <img src={ BLOG_1_IMG_URL } className='' alt='Donee interview' />
                <p><em>{ BLOG_1_DATE}</em></p>
              </a>
            </Col>
            <Col sm={ 6 } className=''>
              <h4 className='home-contents-paragraph'>ドナー（男性）インタビュー</h4>
              <div className='home-contents-donor-blog donor-blog-1'>
                <Row>
                  <Col sm={ 6 } className=''>
                    <a href={ BLOG_2_URL }>
                      <img src={ BLOG_2_IMG_URL } className='' alt='Donor interview 1' />
                    </a>
                  </Col>
                  <Col sm={ 6 } className=''>
                    <a href={ BLOG_2_URL }>
                      <div><em>{ BLOG_2_DATE }</em></div>
                      <h5 className='blog-title'>{ BLOG_2_TITLE }</h5>
                      <p className='blog-body'>{ BLOG_2_BODY }</p>
                    </a>
                  </Col>
                </Row>
              </div>
              <div className='home-contents-donor-blog donor-blog-2'>
                <Row>
                  <Col sm={ 6 } className=''>
                    <a href={ BLOG_3_URL }>
                      <img src={ BLOG_3_IMG_URL } className='' alt='Donor interview 2' />
                    </a>
                  </Col>
                  <Col sm={ 6 } className=''>
                    <a href={ BLOG_3_URL }>
                      <div><em>{ BLOG_3_DATE }</em></div>
                      <h5 className='blog-title'>{ BLOG_3_TITLE }</h5>
                      <p className='blog-body'>{ BLOG_3_BODY }</p>
                    </a>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={ 4 } className=''>
                  <a href='https://blog.tonbebe.jp'>
                    <p>もっと見る &gt;</p>
                  </a>
                </Col>
                <Col lg={ 8 } className=''>
                  <a href='/static/help#help-interview'>
                    <p>インタビューを受けるには？ &gt;</p>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <Container className='home-contents-wrapper'>
          <Row>
            <Col sm={ 12 }>
              <h2 className='home-contents-title'>子供が欲しい</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <h3 className='home-contents-paragraph'>様々な事情で妊娠を望めない女性でも、出産や子育ての幸せを手にする権利をもちろん持っています。Ton Bébéは、精子提供や精子バンクで妊娠を望む全ての方により良い選択をしていただくための精子提供マッチングサービスです。</h3>
            </Col>
          </Row>
          <Row className='home-contents-panel-wrapper'>
            <Col sm={ 4 } className='square-wrapper'>
              <Link to='/static/flow' className='home-contents-panel bg-orange-dots items-middle'>
                <span className='home-contents-panel-title'>精子提供の流れ</span>
              </Link>
            </Col>
            <Col sm={ 4 } className='square-wrapper'>
              <Link to='/static/method' className='home-contents-panel bg-orange-swept items-middle'>
                <span className='home-contents-panel-title'>精子提供の方法</span>
              </Link>
            </Col>
            <Col sm={ 4 } className='square-wrapper'>
              <Link to='/search' className='home-contents-panel bg-orange-striped items-middle'>
                <span className='home-contents-panel-title'>ドナーを探す</span>
              </Link>
            </Col>
          </Row>
        </Container>
        <Container className='home-contents-wrapper'>
          <Row>
            <Col sm={ 12 }>
              <h2 className='home-contents-title'>精子ドナーになる</h2>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 }>
              <h3 className='home-contents-paragraph'>Ton Bébéは妊娠を望む女性により多くの選択肢を提供するため、精子ドナーおよび精子提供ボランティアの方の登録を広く受け付けています。</h3>
            </Col>
          </Row>
          <Row className='home-contents-panel-wrapper'>
            <Col sm={ 4 } className='square-wrapper'>
              <Link to='/static/to_be_donor' className='home-contents-panel bg-blue-toys items-middle'>
                <span className='home-contents-panel-title'>ドナーになる</span>
              </Link>
            </Col>
            <Col sm={ 4 } className='square-wrapper'>
              <Link to='/static/method' className='home-contents-panel bg-blue-dots items-middle'>
                <span className='home-contents-panel-title'>精子提供の方法</span>
              </Link>
            </Col>
            <Col sm={ 4 } className='square-wrapper'>
              <Link to='/static/donor_manners' className='home-contents-panel bg-blue-swept items-middle'>
                <span className='home-contents-panel-title'>ドナーの心得</span>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default HomeContents;
