import React from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Tabs,
  Tab,
  Button,
  Form,
  Image
} from 'react-bootstrap'
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import ApiClient from '../../services/api_client';
import { userTypes } from '../../constants';
import SignupModal from './signup_modal';
import { Spinner } from '../common/spinner';
import {
  FacebookIcon,
  TwitterIcon
} from 'react-share';

class SingleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      password: ''
    }
  }

  handleNameChange = (e) => {
    const input = e.target;

    const val = input.value.toUpperCase();
    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState(
      { name: val },
      () => input.setSelectionRange(start, end)
    );
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleShowModal = (e) => {
    const provider = e.currentTarget.dataset.provider;
    const is_filled = this.isFilled();
    if (provider == 'facebook' || provider == 'twitter' || is_filled) {
      this.props.handleShowModal(provider, this.state);
    }
  }

  isFilled = () => {
    return (
      this.state.name.match(/^[A-Z]{2}$/) &&
      this.state.email != '' &&
      this.state.email.match(/^([a-z0-9_\.\-])+@([a-z0-9_\.\-])+\.[a-z]+/i) &&
      this.state.password.length >= 8
    );
  }

  render() {
    const is_filled = this.isFilled();

    return (
      <div className='row-container single-form'>
        <Row className='first-row'>
          <Col sm={{ span: 12 }} className='text-center'>
            <p>SNSアカウントで登録</p>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 6 }}>
            <Button
              className='btn-sns-logo facebook'
              data-provider='facebook'
              variant='light'
              onClick={ this.handleShowModal }
              block
            >
              <div className='sns-icon'>
                <FacebookIcon
                  size={ 40 }
                  round
                />
              </div>
              &nbsp;&nbsp;Facebook
            </Button>
          </Col>
          <Col sm={{ span: 6 }}>
            <Button
              className='btn-sns-logo twitter'
              data-provider='twitter'
              variant='light'
              onClick={ this.handleShowModal }
              block
            >
              <div className='sns-icon'>
                <TwitterIcon
                  size={ 40 }
                  round
                />
              </div>
              &nbsp;&nbsp;Twitter
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 12 }} className='text-center'>
            <p>（投稿されることはありません）</p>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 12 }} className='text-center'>
             <p>または</p>
          </Col>
        </Row>
        <Form>
          <Form.Group as={ Row }>
            <Form.Label column sm={ 4 } className='signup-form-label'>
              イニシャル
            </Form.Label>
            <Col sm={ 8 }>
              <Form.Control
                type='text'
                value={ this.state.name }
                placeholder='TB'
                maxLength={ 2 }
                onChange={ this.handleNameChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={ 4 } className='signup-form-label'>
              Email
            </Form.Label>
            <Col sm={ 8 }>
              <Form.Control
                type='email'
                value={ this.state.email }
                placeholder='ton@bebe.jp'
                onChange={ this.handleEmailChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={ 4 } className='signup-form-label'>
              パスワード
            </Form.Label>
            <Col sm={ 8 }>
              <Form.Control
                type='password'
                value={ this.state.password }
                placeholder='********'
                onChange={ this.handlePasswordChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row } className='controls'>
            <Col sm={{ span: 4, offset: 2 }} md={{ offset: 1 }} lg={{ offset: 2 }}>
              <Button
                variant=''
                data-provider='email'
                disabled={ !is_filled }
                onClick={ this.handleShowModal }
                className='btn btn-ghost-dark btn-block'
              >新規登録</Button>
            </Col>
            <Col sm={ 6 } className='signup-policy'>
              <a href='/static/terms' target='_blank' className='btn-link-dark'>利用規約</a> に同意の上、新規登録してください。
            </Col>
          </Form.Group>
        </Form>
      </div>
    )
  }
}

SingleForm.propTypes = {
  handleShowModal: PropTypes.func.isRequired
}

class SignupForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      userType: userTypes.DONEE,
      showModal: false,
      provider: '',
      formData: {}
    };
  }

  handleTabSelect = (key) => {
    this.setState({
      userType: key
    });
  }

  handleShowModal = (provider, data) => {
    this.setState({
      showModal: true,
      provider: provider,
      formData: data
    });
  }

  handleHideModal = () => {
    this.setState({
      showModal: false
    });
  }

  switchUserType = () => {
    this.setState({
      userType: this.state.userType === userTypes.DONEE ? userTypes.DONOR : userTypes.DONEE
    });
  }

  handleSignup = () => {
    if (this.state.provider == 'email') {
      this.handleHideModal()
      this.setState({ loading: true });
      ApiClient.post(
        'api/v1/signup',
        {
          name: this.state.formData.name,
          email: this.state.formData.email,
          password: this.state.formData.password,
          password_confirmation: this.state.formData.password,
          user_type: this.state.userType
        },
        (res) => {
          this.setState({ loading: false });
          console.log(res);
          if (!res.ok) {
            toast.error(<span>登録できませんでした。<br/>入力内容をお確かめの上、もう一度お試しください。</span>);
            throw new Error('Network response was not ok.');
          }
        },
        (json) => {
          this.props.history.push('/signup_confirm/' + this.state.formData.email);
        }
      );
    } else if (this.state.provider) {
      window.location.href = `/oauth/${ this.state.provider }?user_type=${ this.state.userType }`
    }
  }

  render() {
    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Tabs
          activeKey={ this.state.key }
          onSelect={ this.handleTabSelect }
          className='signup-form'
          id='user-type-tabs'
        >
          <Tab eventKey={ userTypes.DONEE } title='ドニー登録(女性)' >
            <SingleForm handleShowModal={ this.handleShowModal } userType='DONEE' />
          </Tab>
          <Tab eventKey={ userTypes.DONOR } title='ドナー登録(男性)' >
            <SingleForm handleShowModal={ this.handleShowModal } userType='DONOR' />
          </Tab>
        </Tabs>
        <SignupModal
          show={ this.state.showModal }
          provider={ this.state.provider }
          formData={ this.state.formData }
          userType={ this.state.userType }
          handleHideModal={ this.handleHideModal }
          handleSignup={ this.handleSignup }
          switchUserType={ this.switchUserType }
        />
      </div>
    )
  }
}

export default withRouter(SignupForm);
