import React from 'react';
import PropTypes from 'prop-types';
import AdminHeader from './admin_header';
import AdminMenus from './admin_menus';
import StdApproval from './std_approval';
import StdApprovalEdit from './std_approval/edit';
import SemenApproval from './semen_approval';
import SemenApprovalEdit from './semen_approval/edit';
import Settings from './settings';
import Footer from '../common/footer';
import Auth from '../../services/auth';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { withRouter } from 'react-router';

class Admin extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { menu, id, action } = this.props.match.params;

    let menuPage = null;
    switch (menu) {
      case 'std_approval':
        if (action == 'edit') {
          menuPage = <StdApprovalEdit id={ id } />;
        } else {
          menuPage = <StdApproval />;
        }
        break;
      case 'semen_approval':
        if (action == 'edit') {
          menuPage = <SemenApprovalEdit id={ id }/>
        } else {
          menuPage = <SemenApproval />;
        }
        break;
      case 'settings':
        menuPage = <Settings />;
        break;
      default:
        this.props.history.push('/admin/std_approval');
        break;
    }

    return (
      <div>
        <AdminHeader />
        <Container>
          <Row>
            <Col sm={ 3 }>
              <AdminMenus activeMenu={ menu } />
            </Col>
            <Col sm={ 9 } className='admin-wrapper'>
              { menuPage }
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    )
  }
}

Admin.propTypes = {
  menu: PropTypes.string
}

export default withRouter(Admin);
