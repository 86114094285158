import React from 'react';
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Badge
} from 'react-bootstrap';
import Util from '../../services/util';
import ApiClient from '../../services/api_client';
import { Link } from 'react-router-dom';

class RecommendPanel extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { donor } = this.props;

    const name_f = donor.name.split('').join('.') + '.';

    const std_html = donor.std_certified ? '◎' : '---'
    const semen_html = donor.semen_total != '' ? donor.semen_total : '---'

    const { areas } = donor;
    let areas_html = '';
    for (let i = 0; i < areas.length; i++) {
      if (i > 0) { areas_html += '・' }
      areas_html += Util.val('area', areas[i]);
      if (i == 1 && 3 < areas.length) {
        areas_html += ` +他${ areas.length - 2 }県`;
        break;
      } else if (i > 2) {
        break;
      }
    }

    let { active_in_hour } = donor;
    let active_in = '';
    if (active_in_hour <= 24 || this.props.idx <= 2) {
      active_in = '24時間以内';
    } else if (active_in_hour <= 24 * 3 || this.props.idx <= 4) {
      active_in = '3日以内';
    } else if (active_in_hour <= 24 * 7) {
      active_in = '1週間以内';
    } else if (active_in_hour <= 24 * 14) {
      active_in = '2週間以内';
    } else if (active_in_hour <= 24 * 31) {
      active_in = '1ヶ月以内';
    } else if (active_in_hour <= 24 * 93) {
      active_in = '3ヶ月以内';
    } else {
      active_in = '3ヶ月以上';
    }

    return (
      <div className='home-contents-recommend-panel'>
        <Link to={ `/donor/${ donor.user_no }` }>
          <Container>
            <Row>
              <Col className='recommend-name-wrapper bg-blue-dots items-middle'>
                <h3 className='recommend-name'>{ name_f }</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 10, offset: 2 }} className='recommend-info'>
                <p>{ donor. age } 歳 / { donor.blood_type } 型</p>
                <p>{ donor.height } cm / { donor.weight } kg</p>
                <p>【性病検査】{ std_html }</p>
                <p>【精子品質】{ semen_html }</p>
                <p>{ areas_html }</p>
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 } className='recommend-login'>
                <p>最終ログイン：{ active_in }</p>
              </Col>
            </Row>
          </Container>
        </Link>
      </div>
    )
  }
}

class Recommends extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      donors: []
    }
  }

  componentWillMount = () => {
    let req = ApiClient.postRequestTo(
      '/api/v1/donors/search',
      { std_certified_only: ['TRUE'], limit: 6 }
    );

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Donor] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const users = json.data;

      const donors = users.map(user => {
        const donor_basic_info = user.donor_basic_info || {};
        const donor_physical_info = user.donor_physical_info || {};
        const donor_std = user.donor_std || {};
        const donor_semen = user.donor_semen || {};
        const std_collected_on = new Date(donor_std.collected_on + 'T00:00:00');
        const semen_collected_on = new Date(donor_semen.collected_on + 'T00:00:00');
        const today = new Date();
        const last_year = new Date(today.getFullYear()-1, today.getMonth(), today.getDate());
        return {
          name: user.name || '',
          age: donor_basic_info.birth_date ? Util.getAge(donor_basic_info.birth_date) : '---',
          blood_type: donor_physical_info.blood_type || '---',
          height: donor_physical_info.height || '---',
          weight: donor_physical_info.weight || '---',
          std_certified: donor_std.status === 'CERTIFIED' && std_collected_on > last_year,
          semen_total: donor_semen.status === 'CERTIFIED' && semen_collected_on > last_year ? donor_semen.total : '',
          areas: donor_basic_info.areas || [],
          active_in_hour: user.active_in_hour,
          user_no: user.user_no,
        }
      })

      this.setState({
        donors: donors
      });

    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { donors } = this.state;

    let donorList = [];
    for (let i in donors) {
      donorList.push(<RecommendPanel key={ i } idx={ i } donor={ donors[i] } />)
    }

    donorList.push(
      <div className='home-contents-recommend-panel' key={ 999 }>
        <Link to={ `/search?std_certified_only=TRUE` }>
          <Container>
            <Row>
              <Col className='recommend-name-wrapper bg-blue-dot- items-middle'>
                <h3 className='recommend-name'>&nbsp;</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={{ span: 12, offset: 0 }} className='recommend-info'>
                <p>もっと見る &gt;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
                <p>&nbsp;</p>
              </Col>
            </Row>
            <Row>
              <Col sm={ 12 } className='recommend-login'>
                <p>&nbsp;</p>
              </Col>
            </Row>
          </Container>
        </Link>
      </div>
    );

    return (
      <div>
        <Container className='home-contents-wrapper'>
          <Row>
            <Col sm={ 12 }>
              <h2 className='home-contents-title'>おすすめドナー
                <OverlayTrigger
                  placement='top'
                  overlay={
                    <Tooltip id='abcdef'>
                      性病検査済みのドナーです。
                    </Tooltip>
                  }
                >
                  <span className='h5'><Badge pill variant='secondary'>?</Badge></span>
                </OverlayTrigger>
              </h2>
            </Col>
          </Row>
          <div className='home-contents-recommend-wrapper'>
            <div className='home-contents-recommend-list'>
              { donorList.length > 1 ? donorList : 'ロード中...' }
            </div>
          </div>
        </Container>
      </div>
    )
  }
}

export default Recommends;
