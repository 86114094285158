import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { Helmet } from 'react-helmet';
import SignupHeader from './signup_header';
import SignupForm from './signup_form';
import { ja } from '../../locales/ja';

class Signup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Ton Bébé | 新規登録</title>
          <meta name='description' content={ `${ ja['site_description'] }このページからTon Bébéに新規登録してください。` } />
        </Helmet>
        <SignupHeader />
        <Container className='signup-form-wrapper'>
          <Row>
            <Col sm={{ span: 6, offset: 3}}>
              <SignupForm />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Signup;
