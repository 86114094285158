import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import Util from '../../services/util';

class PhysicalInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { donor } = this.props;
    return (
      <Container className='profile-content-wrapper'>
        <Row>
          <Col className='profile-content-box'>
            <Row>
              <Col md={ 12 } className='profile-content-title'>
                <span>身体情報</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>血液型</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ donor.blood_type } 型</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>身長</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ donor.height } cm</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>体重</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ donor.weight } kg</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>体格</span>
              </Col>
              <Col xs={ 7 } md={ 9 } className='profile-content-item-d'>
                <span>{ Util.val('body_figure', donor.body_figure) }</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

PhysicalInfo.propTypes = {
  donor: PropTypes.object
}

export default PhysicalInfo;
