const post = (endpoint, data, cbRes, cbData) => {
  let request = new Request(endpoint, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(data)
  });

  fetch(request).then(res => {
    return cbRes(res);
  }).then(data => {
    return cbData(data);
  }).catch(error => {
    console.error(error);
  })
}

const getRequestTo = (endpoint) => {
  return new Request(endpoint, {
    method: 'GET'
  });
}

const postRequestTo = (endpoint, data) => {
  return new Request(endpoint, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(data)
  });
}

const patchRequestTo = (endpoint, data) => {
  return new Request(endpoint, {
    method: 'PATCH',
    headers: new Headers({
      'Content-Type': 'application/json'
    }),
    body: JSON.stringify(data)
  });
}

const secureGetRequestTo = (endpoint) => {
  const token = localStorage.getItem('access_token');

  return new Request(endpoint, {
    method: 'GET',
    headers: new Headers({
      'Authorization': `Bearer ${token}`
    })
  });
}

const securePostRequestTo = (endpoint, data) => {
  const token = localStorage.getItem('access_token');

  return new Request(endpoint, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }),
    body: JSON.stringify(data)
  });
}

const securePatchRequestTo = (endpoint, data) => {
  const token = localStorage.getItem('access_token');

  return new Request(endpoint, {
    method: 'PATCH',
    headers: new Headers({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }),
    body: JSON.stringify(data)
  });
}

const secureDeleteRequestTo = (endpoint) => {
  const token = localStorage.getItem('access_token');

  return new Request(endpoint, {
    method: 'DELETE',
    headers: new Headers({
      'Authorization': `Bearer ${token}`
    })
  });
}

export default {
  post,
  getRequestTo,
  postRequestTo,
  patchRequestTo,
  secureGetRequestTo,
  securePostRequestTo,
  securePatchRequestTo,
  secureDeleteRequestTo
};
