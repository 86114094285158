import React from 'react';
import logoImg from '../../../assets/images/ton_bebe_logo_g_t.png';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class VisitorHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className='header-wrapper bg-blue-mesh' as='header'>
        <Row className='items-middle' as='nav'>
          <Col lg={ 7 } md={ 6 } xs={ 6 }>
            <Link to='/'>
              <img src={ logoImg } className='header-logo' alt='Ton Bebe' />
            </Link>
          </Col>
          <Col lg={ 3 } md={ 3 } className='text-center d-none d-md-block'>
            <Link to='/search' className='btn-ghost-dark pull-right btn btn-lg'>
              ドナーを探す
            </Link>
          </Col>
          <Col lg={ 2 } md={ 3 } xs={ 6 } className='text-center'>
            <Link to='/login' className='btn-link-dark btn btn-lg'>
              ログイン
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default VisitorHeader;
