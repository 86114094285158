import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { ja } from '../../locales/ja';

class Method extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='static-contents-wrapper'>
        <Helmet>
          <title>Ton Bébé | 精子提供の方法</title>
          <meta name='description' content={ `${ ja['site_description'] }このページではTon Bébéの利用者が多く利用している精子提供の方法を説明します。` } />
        </Helmet>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>精子提供にはシリンジ法とタイミング法の２つの方法があります。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>シリンジ法</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>シリンジ法とは、先端がシリコンでできた注射器を使用し、精子をスポイトのように吸い上げて膣内に注入する方法です。</p>
            <p className='static-contents-paragraph'>男性との性行為に抵抗のある場合はこの方法を採用しましょう。</p>
            <p className='static-contents-paragraph'>男性が射精するための清潔な空間や女性が注入するための個室空間を確保できる場所、例えばホテルのロビーなどが待ち合わせ場所に選ばれることが多いようです。</p>
            <p className='static-contents-paragraph'>シリンジ法キットはインターネットで購入可能ですので、事前に準備しておきましょう。</p>
            <p className='static-contents-paragraph'>お勧めはこちら => <a href="//af.moshimo.com/af/c/click?a_id=1784748&amp;p_id=54&amp;pc_id=54&amp;pl_id=616&amp;url=https%3A%2F%2Fitem.rakuten.co.jp%2Fbabylife-lab%2Fmeeta-202010%2F&amp;m=http%3A%2F%2Fm.rakuten.co.jp%2Fbabylife-lab%2Fi%2F10000038%2F" rel="nofollow"><img src="//thumbnail.image.rakuten.co.jp/@0_mall/babylife-lab/cabinet/sozai/20201026_meeta03.jpg?_ex=128x128" alt="" /></a><img src="//i.moshimo.com/af/i/impression?a_id=1784748&amp;p_id=54&amp;pc_id=54&amp;pl_id=616" alt="" width="1" height="1" /></p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>タイミング法</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>タイミング法とは、排卵のタイミングに合わせて自然な性行為により精子提供を行う方法です。</p>
            <p className='static-contents-paragraph'>シリンジ法に比べてより新鮮な精子を提供できますが、相互にパートナーがいる場合、より深い理解が必要とされる方法です。条件をしっかり合意のうえ、採用しましょう。</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Method;
