import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col,
  Image
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import imgSperm from '../../../assets/images/who_sperm_test_general.png';
import { ja } from '../../locales/ja';

class Help extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='static-contents-wrapper'>
        <Helmet>
          <title>Ton Bébé | ヘルプ</title>
          <meta name='description' content={ `${ ja['site_description'] }このページではTon Bébéのよくあるお問い合わせを提示します。` } />
        </Helmet>
        <Row>
          <Col xs={ 12 }>
            <h2 className=''>よくあるお問い合わせ</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph help'></p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h3 className=''>Ton Bébéとは何ですか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph help'>LGBT、FTM、選択的シングルマザーなど、子供を望む女性が安心して精子ドナーを探すことができる、精子提供マッチングサービスです。性病検査や精液検査の結果からドナーを検索することができます。</p>
          </Col>
        </Row>

        <Row>
          <Col xs={ 12 }>
            <h3 className=''>無料で利用できますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>Ton Bébéは無料でご利用いただけます。</p>
            <p className='static-contents-paragraph'>ドナーをお探しの方は、ドナーへの謝礼や交通費などの実費負担がかかりますが、ドナーによっては受け取らない方もおります。事前にメッセージをやりとりして両者間で決めましょう。</p>
            <p className='static-contents-paragraph help'>ドナーの方は、性病・精液検査結果の登録に、それぞれ3,000円（両方同時の場合は5,000円）を手数料としてお支払いいただきます。ただし、検査結果を登録しないでドナーとして登録することも可能です。</p>
          </Col>
        </Row>

        <Row>
          <Col xs={ 12 }>
            <h3 className=''>登録したことが周囲に知られることはありますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph help'>Ton Bébéではお預かりした個人情報は厳重に管理しております。また、FacebookなどSNS連携でログインした場合でも、投稿や友人へ知られることはありませんのでご安心ください。</p>
          </Col>
        </Row>

        <Row>
          <Col xs={ 12 }>
            <h3 className=''>ドナー登録したけどメッセージが届きません。何をすれば良いですか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>ドナー登録後、まずはプロフィールを充実させましょう。次に性病/精液検査の結果を登録しましょう。</p>
            <p className='static-contents-paragraph'>Ton Bébéでは、ドニー（女性）が条件に合うドナーを検索してメッセージを送信する流れになります。メッセージを受信した際にはご登録のメールアドレスに通知のメールが送信されます。</p>
            <p className='static-contents-paragraph'>傾向として、「精子提供を行う理由」など、精子提供への想いをプロフィールに記載されているドナーのほうが多くのメッセージを受け取っております。ご自身の考えをプロフィールに記載されてみてはいかがでしょうか。</p>
            <p className='static-contents-paragraph help'>また、実績として、性病/精液検査の両方を登録されている方は登録されていない方と比較して20倍程度の確率でメッセージを受け取っています。</p>
          </Col>
        </Row>

        <Row>
          <Col xs={ 12 }>
            <h3 id='help-interview'>Ton Bébéブログのインタビューを受けることはできますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>Ton Bébéでは、実際にご登録いただいているユーザー様の声をお届けするために、登録ドナー・ドニー様のインタビュー記事を<a href='https://blog.tonbebe.jp'>Ton Bébéブログ</a>に掲載しております</p>
            <p className='static-contents-paragraph help'>掲載をご希望の方は以下よりお申し込みください。<br/>・ドナー（男性）の方は<a target='_blank' href='https://forms.gle/KL47QTQBnxnFbeHFA'>こちら</a><br/>・ドニー（女性）の方は<a target='_blank' href='https://forms.gle/isocigqrwo6WCbEb8'>こちら</a></p>
          </Col>
        </Row>

        <Row>
          <Col xs={ 12 }>
            <h3>性病・精液検査を行う検査機関の指定はありますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>検査機関の指定はありません。ただし、以下の検査項目は登録時に入力必須となっております。</p>
            <p className=''>【性病検査】</p>
            <ul>
              <li>HIV</li>
              <li>梅毒</li>
              <li>クラミジア</li>
              <li>淋菌</li>
            </ul>
            <p className=''>【精液検査】</p>
            <ul>
              <li>精液量</li>
              <li>精子濃度</li>
              <li>総精子数</li>
            </ul>
            <p className='static-contents-paragraph'>※上記以外でも検査結果書類に記載の項目は全てご入力いただいております。
            <br/>※上記必須項目は仕様により変更になることがありあます。</p>
            <p className='static-contents-paragraph help'>ほとんどの検査機関・検査キットで上記項目は対応しておりますが、Ton Bébéおすすめの検査キットは<Link to='/static/to_be_donor#recommend-kit-1'>こちら</Link>をご覧ください。</p>
          </Col>
        </Row>

        <Row id='help-test-reliability'>
          <Col xs={ 12 }>
            <h3>性病・精液検査結果は信頼できますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>Ton Bébéではドナーが検査結果を登録する際に、証明書を提出していただいております。その内容をTon Bébé運営事務局にて検証の上、正しい検査結果をサイト上に表示しておりますので、安心してご利用ください。</p>
            <p className='static-contents-paragraph help'>ただし、より新しい検査結果をドナーがお持ちの場合もありますので、面談時に必ずご自身の目で確認するようにしてください。</p>
          </Col>
        </Row>

        <Row id='help-sperm-calc'>
          <Col xs={ 12 }>
            <h3>精子品質とはなんですか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>Ton Bébéでは、精液検査結果を５段階（A〜E）で表しています。<br/>WHO（世界保健機関）が発表した、以下の一般成人男性の精液検査のデータを基に、計算しています。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 } md={ 8 }>
            <Image src={ imgSperm } fluid />
            <p>出典: <a href='https://academic.oup.com/humupd/article/16/3/231/639175'>World Health Organization reference values for human semen characteristics</a></p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className=''>各検査項目について、パーセンタイルを 5, 25, 50, 90 で区切り、</p>
              <ul>
                <li>A : 90以上</li>
                <li>B : 50以上</li>
                <li>C : 25以上</li>
                <li>D : 5以上</li>
                <li>E : 5未満</li>
              </ul>
             <p className='static-contents-paragraph help'>として算出し、それらの平均値を「精子品質」として表示しています。
            </p>
          </Col>
        </Row>

        <Row>
          <Col xs={ 12 }>
            <h3>退会はできますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>退会は<Link to='/mypage/account'>アカウントページ</Link>（要ログイン）の「退会する」ボタンからいつでも可能です。</p>
            <p className='static-contents-paragraph help'>退会した場合は、検査の審査結果を含めてご登録いただいた情報を復元できませんのでご了承ください。</p>
          </Col>
        </Row>

        <Row id='help-inquiry-form'>
          <Col xs={ 12 }>
            <h3>お問い合わせフォームはありますか？</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph help'>解決しない場合は、<a target='_blank' href='https://forms.gle/RzBbwPV2g2yLG8Ur7'>こちら</a>からお問い合わせください。</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Help;
