import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Alert
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { FormCheckGroup } from '../../common/custom_form_components';
import ApiClient from '../../../services/api_client';
import Auth from '../../../services/auth';
import { toast } from 'react-toastify';
import { Spinner } from '../../common/spinner';

class DonorProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      is_public: false,
      user_no: '',
      name: '',
      birth_date: '',
      areas: [],
      college_type: '',
      major_type: '',
      college: '',
      major: '',
      marriage: '',
      son: '',
      daughter: '',
      comments: '',
      blood_type: '',
      blood_rh: '',
      height: '',
      weight: '',
      body_figure: '',
      methods: [],
      gratuity: '',
      std_status: '',
      std_expired: false,
      semen_status: '',
      semen_expired: false
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donors/${ Auth.userId() }`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET User] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const user = json.data;
      const donor_basic_info = user.donor_basic_info || {};
      const donor_physical_info = user.donor_physical_info || {};
      const donor_request = user.donor_request || {};
      const donor_std = user.donor_std || {};
      const donor_semen = user.donor_semen || {};
      this.setState({
        is_public: donor_basic_info.is_public,
        user_no: user.user_no,
        name: user.name || '',
        birth_date: donor_basic_info.birth_date ? new Date(donor_basic_info.birth_date) : '',
        areas: donor_basic_info.areas || [],
        college_type: donor_basic_info.college_type || 'PUBLIC',
        major_type: donor_basic_info.major_type || '',
        college: donor_basic_info.college || '',
        major: donor_basic_info.major || '',
        marriage: donor_basic_info.marriage || 'UNMARRIED',
        son: donor_basic_info.son || '',
        daughter: donor_basic_info.daughter || '',
        comments: donor_basic_info.comments || '',
        blood_type: donor_physical_info.blood_type || 'A',
        blood_rh: donor_physical_info.blood_rh || '',
        height: donor_physical_info.height || '',
        weight: donor_physical_info.weight || '',
        body_figure: donor_physical_info.body_figure || 'NORMAL',
        methods: donor_request.methods || [],
        gratuity: donor_request.gratuity || '',
        std_status: donor_std.status,
        std_expired: donor_std.expired,
        semen_status: donor_semen.status,
        semen_expired: donor_semen.expired
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  execSave = (success_msg) => {
    this.setState({ loading: true });
    let req = ApiClient.securePatchRequestTo(
      `/api/v1/donors/${ Auth.userId() }`,
      this.state
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Update account] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success(success_msg);
    }).catch((err) => {
      toast.error('保存に失敗しました。時間をおいてお試しください。');
    });
  }

  handleTempSaveClick = (e) => {
    this.setState({
      is_public: false
    }, () => { this.execSave('保存しました。') })
  }

  handlePublicClick = (e) => {
    if (!this.state.name.match(/^[A-Z]{2}$/)) {
      toast.error('イニシャルは英大文字2文字です。');
      return;
    }

    this.setState({
      is_public: true
    }, () => { this.execSave('公開しました。') })
  }

  handleUnpublicClick = (e) => {
    this.setState({
      is_public: false
    }, () => { this.execSave('非公開にしました。') })
  }

  handleNameChange = (e) => {
    const input = e.target;

    const val = input.value.toUpperCase();
    const start = input.selectionStart;
    const end = input.selectionEnd;

    this.setState(
      { name: val },
      () => input.setSelectionRange(start, end)
    );
  }

  handleTextChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleSelectChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    })
  }

  handleBirthDateChange = (date) => {
    this.setState({
      birth_date: date
    })
  }

  handleAreaChecked = (e) => {
    let areas = this.state.areas;
    const data_key = e.currentTarget.dataset.key;
    if (e.target.checked) {
      if (areas.indexOf(data_key) < 0) {
        areas.push(data_key);
      }
    } else {
      const idx = areas.indexOf(data_key);
      if (idx >= 0) {
        areas.splice(idx, 1);
      }
    }
    this.setState({
      areas: areas
    })
  }

  handleMethodsChecked = (e) => {
    let methods = this.state.methods;
    const data_key = e.currentTarget.dataset.key;
    if (e.target.checked) {
      if (methods.indexOf(data_key) < 0) {
        methods.push(data_key);
      }
    } else {
      const idx = methods.indexOf(data_key);
      if (idx >= 0) {
        methods.splice(idx, 1);
      }
    }
    this.setState({
      methods: methods
    })
  }

  render() {
    let publicStatusText = '公開中';
    let publicStatusClass = '';
    if (!this.state.is_public) {
      publicStatusText = '未公開';
      publicStatusClass = 'text-red';
    }

    let stdStatusText = '登録済';
    let stdStatusClass = '';
    if (this.state.std_status != 'CERTIFIED' || this.state.std_expired) {
      stdStatusText = '未登録';
      stdStatusClass = 'text-red';
    }

    let semenStatusText = '登録済';
    let semenStatusClass = '';
    if (this.state.semen_status != 'CERTIFIED' || this.state.semen_expired) {
      semenStatusText = '未登録';
      semenStatusClass = 'text-red';
    }

    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Row>
          <Col>
            <Alert variant='warning' className='mypage-alert'>
              <h5>Ton Bébéにご登録いただきありがとうございます！</h5>
              <p>
                まずはプロフィールを充実させて公開しましょう。<br/>
                次に、<Link to='/mypage/std_semen'>性病/精液検査結果の登録</Link>をすることで、多くのドニーに注目されるようになります。
              </p>
              <ul>
                <li>プロフィール： <span className={ publicStatusClass }>【{ publicStatusText }】</span></li>
                <li>性病検査結果： <span className={ stdStatusClass }>【{ stdStatusText }】</span></li>
                <li>精液検査結果： <span className={ semenStatusClass }>【{ semenStatusText }】</span></li>
              </ul>
            </Alert>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className='mypage-title'>プロフィール 【{ publicStatusText }】</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col className='mypage-content-box'>
              <Row>
                <Col xs={ 12 } className='mypage-content-title'>
                  <span>基本情報</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>ドナーNo.</span>
                </Col>
                <Col xs={ 7 } md={ 9 } className='mypage-content-item-d'>
                  <span>{ this.state.user_no }</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>イニシャル</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    type='text'
                    id='name'
                    value={ this.state.name }
                    placeholder='TB'
                    maxLength={ 2 }
                    onChange={ this.handleNameChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>生年月日</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <DatePicker
                    className='form-control'
                    dateFormat='yyyy/MM/dd'
                    selected={ this.state.birth_date }
                    onChange={ this.handleBirthDateChange }
                  />
                </Col>
              </Row>
              <Row className=''>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>エリア</span>
                </Col>
                <Col md={ 9 } className='mypage-content-item-d'>

                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      HOKKAIDO: '北海道'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      AOMORI: '青森',
                      IWATE: '岩手',
                      MIYAGI: '宮城',
                      AKITA: '秋田',
                      YAMAGATA: '山形',
                      FUKUSHIMA: '福島'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      TOKYO: '東京',
                      KANAGAWA: '神奈川',
                      CHIBA: '千葉',
                      SAITAMA: '埼玉',
                      IBARAK: '茨城',
                      TOCHIGI: '栃木',
                      GUNMA: '群馬'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      NIIGATA: '新潟',
                      TOYAMA: '富山',
                      ISHIKAWA: '石川',
                      FUKUI: '福井',
                      YAMANASHI: '山梨',
                      NAGANO: '長野',
                      GIFU: '岐阜'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      SHIZUOKA: '静岡',
                      AICHI: '愛知',
                      MIE: '三重'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      OSAKA: '大阪',
                      HYOGO: '兵庫',
                      KYOTO: '京都',
                      SHIGA: '滋賀',
                      NARA: '奈良',
                      WAKAYAMA: '和歌山'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      TOTTORI: '鳥取',
                      SHIMANE: '島根',
                      OKAYAMA: '岡山',
                      HIROSHIMA: '広島',
                      YAMAGUCHI: '山口'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      TOKUSHIMA: '徳島',
                      KAGAWA: '香川',
                      EHIME: '愛媛',
                      KOCHI: '高知'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                  <FormCheckGroup
                    checkedIds={ this.state.areas }
                    targetData={{
                      FUKUOKA: '福岡',
                      SAGA: '佐賀',
                      NAGASAKI: '長崎',
                      KUMAMOTO: '熊本',
                      OITA: '大分',
                      MIYAZAKI: '宮崎',
                      KAGOSHIMA: '鹿児島',
                      OKINAWA: '沖縄'
                    }}
                    handleChecked={ this.handleAreaChecked }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>最終学歴</span>
                </Col>
                <Col xs={ 7 } md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='college_type'
                    value={ this.state.college_type }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='PUBLIC'>国公立大学</option>
                    <option value='PRIVATE'>私立大学</option>
                    <option value='HIGHSCHOOL'>高等学校</option>
                    <option value='OTHER'>その他</option>
                  </Form.Control>
                </Col>
                <Col xs={ 5 } md={{ span: 4, offset: 0 }} className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='major_type'
                    value={ this.state.major_type }
                    onChange={ this.handleSelectChange }
                  >
                    <option value=''>---</option>
                    <option value='BS'>理系</option>
                    <option value='BA'>文系</option>
                    <option value='OTHER'>その他</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>大学・高校名</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    type='text'
                    id='college'
                    value={ this.state.college }
                    placeholder='◯◯大学'
                    onChange={ this.handleTextChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>学部・学科</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    type='text'
                    id='major'
                    value={ this.state.major }
                    placeholder='△△学部'
                    onChange={ this.handleTextChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>婚姻歴</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='marriage'
                    value={ this.state.marriage }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='UNMARRIED'>未婚</option>
                    <option value='MARRIED'>既婚</option>
                    <option value='DIVORCED'>離婚</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>血縁関係のある子供</span>
                </Col>
                <Col xs={ 2 } md={ 1 } className='mypage-content-item-d'>
                  <span>男</span>
                </Col>
                <Col xs={ 4 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='son'
                    value={ this.state.son }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='0'>0</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5以上</option>
                  </Form.Control>
                </Col>
                <Col xs={ 2 } md={{ span: 1, offset: 0 }} className='mypage-content-item-d'>
                  <span>女</span>
                </Col>
                <Col xs={ 4 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='daughter'
                    value={ this.state.daughter }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='0'>0</option>
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5'>5以上</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className=''>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>自己PR</span>
                </Col>
                <Col md={ 9 } className='mypage-content-item-d'>
                  <Form.Control className='mypage-comments'
                    as='textarea'
                    id='comments'
                    value={ this.state.comments }
                    placeholder=''
                    onChange={ this.handleTextChange }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-content-box'>
              <Row>
                <Col xs={ 12 } className='mypage-content-title'>
                  <span>身体情報</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>血液型</span>
                </Col>
                <Col md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='blood_type'
                    value={ this.state.blood_type }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    <option value='AB'>AB</option>
                    <option value='O'>O</option>
                  </Form.Control>
                </Col>
                <Col md={{ span: 1, offset: 1 }} className='mypage-content-item-h'>
                  <span>Rh</span>
                </Col>
                <Col md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='blood_rh'
                    value={ this.state.blood_rh }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='UNKNOWN'>不明</option>
                    <option value='PLUS'>+</option>
                    <option value='MINUS'>-</option>
                  </Form.Control>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>身長</span>
                </Col>
                <Col xs={ 9 } md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='height'
                    value={ this.state.height }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 3 } md={ 1 } className='mypage-content-item-d'>
                  <span>cm</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>体重</span>
                </Col>
                <Col xs={ 9 } md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='weight'
                    value={ this.state.weight }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 3 } md={ 1 } className='mypage-content-item-d'>
                  <span>kg</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>体格</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    as='select'
                    id='body_figure'
                    value={ this.state.body_figure }
                    onChange={ this.handleSelectChange }
                  >
                    <option value='SKINNY'>痩せている</option>
                    <option value='THIN'>細め</option>
                    <option value='NORMAL'>普通</option>
                    <option value='THICK'>がっちり</option>
                    <option value='FAT'>太め</option>
                  </Form.Control>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-content-box'>
              <Row>
                <Col xs={ 12 } className='mypage-content-title'>
                  <span>精子提供について</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>提供方法</span>
                </Col>
                <Col md={ 9 } className='mypage-content-item-d'>
                  <FormCheckGroup
                    checkedIds={ this.state.methods }
                    targetData={{
                      SYRINGE: 'シリンジ法',
                      TIMING: 'タイミング法'
                    }}
                    dataType='method'
                    handleChecked={ this.handleMethodsChecked }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 2, offset: 1 }} className='mypage-content-item-h'>
                  <span>希望謝礼金</span>
                </Col>
                <Col xs={ 9 } md={ 4 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='gratuity'
                    value={ this.state.gratuity }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 3 } md={ 1 } className='mypage-content-item-d'>
                  <span>円</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 4, offset: 1}} className='mypage-submit'>
              <Button
                variant=''
                className='btn-ghost-dark'
                onClick={ this.handlePublicClick }
                block>保存 ＆ 公開</Button>
            </Col>
            <Col md={{ span: 4, offset: 2 }} className='mypage-submit'>
              { this.state.is_public ?
                <Button
                  variant=''
                  className='btn-ghost-dark'
                  onClick={ this.handleUnpublicClick }
                  block>非公開にする</Button>
                :
                <Button
                  variant=''
                  className='btn-ghost-dark'
                  onClick={ this.handleTempSaveClick }
                  block>一時保存</Button>
              }
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default DonorProfile;
