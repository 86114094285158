import React from 'react';
import VisitorHeader from '../common/visitor_header';
import UserHeader from '../common/user_header';
import PageTitle from '../common/page_title';
import ConvList from './conv_list';
import MessageView from './message_view';
import Footer from '../common/footer';
import Auth from '../../services/auth';
import { Spinner } from '../common/spinner';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'

class Messages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      active_conv_id: ''
    }
  }

  handleLoading = (is_loading) => {
    this.setState({ loading: is_loading });
  }

  handleConvSelect = (conv_id) => {
    this.setState({ active_conv_id: conv_id })
  }

  render() {
    let headerSet = null;
    if (Auth.isLoggedIn()) {
      headerSet = <UserHeader />
    } else {
      headerSet = <VisitorHeader />;
    }

    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        { headerSet }
        <PageTitle title='メッセージ' />
        <Container>
          <Row>
            <Col sm={ 3 }>
              <ConvList
                active_conv_id={ this.state.active_conv_id }
                handleConvSelect={ this.handleConvSelect }
              />
            </Col>
            <Col sm={ 9 }>
              <MessageView
                active_conv_id={ this.state.active_conv_id }
                handleLoading={ this.handleLoading }
              />
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default Messages;
