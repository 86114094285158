import React from 'react';
import logoImg from '../../../assets/images/ton_bebe_logo_g_t.png';
import SignupForm from '../signup/signup_form';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share';

class Hero extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const share_url = env.APP_URL;
    const title = 'Ton Bébé | 精子提供マッチングサービス';

    return (
      <Container fluid className='hero-wrapper bg-blue-mesh' as='header'>
        <Row className='items-middle hero-header' as='nav'>
          <Col lg={ 7 } md={ 6 } className='d-none d-md-block'>
            <Link to='/'>
              <img src={ logoImg } className='hero-logo' alt='Ton Bebe' />
            </Link>
          </Col>
          <Col md={ 2 } className='d-none d-xl-block text-center'>
            <Link to='/search' className='btn-ghost-dark pull-right btn btn-lg'>
              ドナーを探す
            </Link>
          </Col>
          <Col lg={ 2 } md={ 3 } xs={ 6 } className='d-xl-none text-center'>
            <Link to='/signup' className='btn-ghost-dark pull-right btn btn-lg'>
              新規登録
            </Link>
          </Col>
          <Col lg={ 2 } md={ 3 } xs={ 6 } className='text-center'>
            <Link to='/login' className='btn-link-dark btn btn-lg'>
              ログイン
            </Link>
          </Col>
        </Row>
        <Row className='items-middle'>
          <Col lg={ 12 } xl={ 6 } className='text-center'>
            <Link to='/' className='d-md-none'>
              <img src={ logoImg } className='hero-logo' alt='Ton Bebe' />
            </Link>
            <h2 className='hero-message'>子供を望む全ての人に</h2>
            <Link to='/search' className='btn-ghost-dark pull-right btn btn-lg d-xl-none hero-search-btn'>
              ドナーを探す
            </Link>
            <div className='sns-share-btn-wrapper'>
              <FacebookShareButton
                url={ share_url }
                quote={ title }
                hashtag='#精子提供'
                className='sns-share-btn'>
                <FacebookIcon
                  size={ 40 }
                  round={ false }
                />
              </FacebookShareButton>
              <TwitterShareButton
                url={ share_url }
                title={ title }
                hashtags={ ['精子提供', '精子ドナー', '妊活']}
                className='sns-share-btn'>
                <TwitterIcon
                  size={ 40 }
                  round={ false }
                />
              </TwitterShareButton>
            </div>
          </Col>
          <Col md={{ span: 4, offset: 1}} className='d-none d-xl-block'>
            <SignupForm />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Hero;
