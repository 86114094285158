import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image
} from 'react-bootstrap'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ApiClient from '../../services/api_client';
import Auth from '../../services/auth';
import { toast } from 'react-toastify';

class ResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      password_confirmation: ''
    }
  }

  componentDidMount(){
    this.focusEmail();
  }

  focusEmail = () => {
    let input = ReactDOM.findDOMNode(this.refs.emailInput);
    input && input.select();
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handlePasswordConfChange = (e) => {
    this.setState({
      password_confirmation: e.target.value
    })
  }

  handleReset = (e) => {
    const { email, password, password_confirmation } = this.state;

    if (!email || !password || !password_confirmation) {
      return;
    }

    if (!email.match(/^([a-z0-9_\.\-])+@([a-z0-9_\.\-])+\.[a-z]+/i)) {
      toast.error(<span>メールアドレスを正しく入力してください。</span>);
      return;
    }

    if (password.length < 8) {
      toast.error(<span>パスワードは８文字以上にしてください。</span>);
      return;
    }

    if (password != password_confirmation) {
      toast.error(<span>パスワードは確認用と同じ値を入れてください。</span>);
      return;
    }

    let req = ApiClient.patchRequestTo(
      `/api/v1/password_resets/${ this.props.token }`,
      {
        email: this.state.email,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation
      }
    );

    fetch(req).then((res) => {
      if (!res.ok) {
        toast.error(<span>パスワードのリセットに失敗しました。<br/>もう一度お試しください。</span>);
        this.focusEmail();
        throw Error(`[Login failed] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success(<span>パスワードをリセットしました。</span>);
      this.props.history.push('/login');
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    return (
      <Container className='login-form-wrapper'>
        <Row className='or-row'>
          <Col sm={{ span: 4, offset: 4}} className=''>
             <p>新しいパスワードを入力してください。</p>
          </Col>
        </Row>
        <Form>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 2, offset: 2}} className='text-right'>
              Email
            </Form.Label>
            <Col sm={ 4 }>
              <Form.Control
                ref='emailInput'
                type='email'
                value={ this.state.email }
                placeholder='ton@bebe.jp'
                onChange={ this.handleEmailChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 2, offset: 2}} className='text-right'>
              パスワード
            </Form.Label>
            <Col sm={ 4 }>
              <Form.Control
                type='password'
                value={ this.state.password }
                placeholder='********'
                onChange={ this.handlePasswordChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 2, offset: 2}} className='text-right'>
              パスワード（確認用）
            </Form.Label>
            <Col sm={ 4 }>
              <Form.Control
                type='password'
                value={ this.state.password_confirmation }
                placeholder='********'
                onChange={ this.handlePasswordConfChange }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 2, offset: 4 }}>
              <Link
                to='#'
                onClick={ this.handleReset }
                className='btn btn-ghost-dark'
              >パスワード変更</Link>
            </Form.Label>
          </Form.Group>
        </Form>
      </Container>
    )
  }
}

ResetForm.propTypes = {
  token: PropTypes.string.isRequired
}

export default withRouter(ResetForm);
