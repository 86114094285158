export const ja = {
  area: {
    HOKKAIDO: '北海道',
    AOMORI: '青森',
    IWATE: '岩手',
    MIYAGI: '宮城',
    AKITA: '秋田',
    YAMAGATA: '山形',
    FUKUSHIMA: '福島',
    TOKYO: '東京',
    KANAGAWA: '神奈川',
    CHIBA: '千葉',
    SAITAMA: '埼玉',
    IBARAK: '茨城',
    TOCHIGI: '栃木',
    GUNMA: '群馬',
    NIIGATA: '新潟',
    TOYAMA: '富山',
    ISHIKAWA: '石川',
    FUKUI: '福井',
    YAMANASHI: '山梨',
    NAGANO: '長野',
    GIFU: '岐阜',
    SHIZUOKA: '静岡',
    AICHI: '愛知',
    MIE: '三重',
    OSAKA: '大阪',
    HYOGO: '兵庫',
    KYOTO: '京都',
    SHIGA: '滋賀',
    NARA: '奈良',
    WAKAYAMA: '和歌山',
    TOTTORI: '鳥取',
    SHIMANE: '島根',
    OKAYAMA: '岡山',
    HIROSHIMA: '広島',
    YAMAGUCHI: '山口',
    TOKUSHIMA: '徳島',
    KAGAWA: '香川',
    EHIME: '愛媛',
    KOCHI: '高知',
    FUKUOKA: '福岡',
    SAGA: '佐賀',
    NAGASAKI: '長崎',
    KUMAMOTO: '熊本',
    OITA: '大分',
    MIYAZAKI: '宮崎',
    KAGOSHIMA: '鹿児島',
    OKINAWA: '沖縄'
  },
  college_type: {
    PUBLIC: '国公立大学',
    PRIVATE: '私立大学',
    HIGHSCHOOL: '高等学校'
  },
  major_type: {
    BS: '理系',
    BA: '文系',
    OTHER: 'その他'
  },
  marriage: {
    UNMARRIED: '未婚',
    MARRIED: '既婚',
    DIVORCED: '離婚'
  },
  body_figure: {
    SKINNY: '痩せている',
    THIN: '細め',
    NORMAL: '普通',
    THICK: 'がっちり',
    FAT: '太め'
  },
  methods: {
    SYRINGE: 'シリンジ法',
    TIMING: 'タイミング法'
  },
  pos_neg: {
    NA: '検査対象外',
    MINUS: '陰性',
    PLUS: '陽性'
  },
  record_status: {
    PENDING: '承認待ち',
    APPROVED: '承認済み',
    REJECTED: '拒否',
    REPORTED: '通報'
  },
  std_status: {
    DRAFT: '未提出',
    SUBMITTED: '確認待ち',
    REMANDED: '要修正',
    CERTIFIED: '承認済み'
  },
  semen_status: {
    DRAFT: '未提出',
    SUBMITTED: '確認待ち',
    REMANDED: '要修正',
    CERTIFIED: '承認済み'
  },
  site_description: 'Ton Bébéは、性病・精液検査の結果からドナーを探すことができるSNS型の精子提供マッチングサービスです。'
};
