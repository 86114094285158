import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Row,
  Col
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  CardElement,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from 'react-stripe-elements';
import Util from '../../services/util';

class CheckoutForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      is_valid_card_no: false,
      is_valid_expired_on: false,
      is_valid_cvc: false
    }
    this.submit = this.submit.bind(this);
  }

  handleNoChanged = (e) => {
    this.setState({ is_valid_card_no: e.complete })
  }

  handleExpiredOnChanged = (e) => {
    this.setState({ is_valid_expired_on: e.complete })
  }

  handleCvcChanged = (e) => {
    this.setState({ is_valid_cvc: e.complete })
  }

  handleClose = (e) => {
    this.setState({
      is_valid_card_no: false,
      is_valid_expired_on: false,
      is_valid_cvc: false
    })
    this.props.handleClose();
  }

  async submit(ev) {
    const { token } = await this.props.stripe.createToken({ name: this.props.name });

    if (!token) {
      toast.error('カードが利用できません。内容をお確かめのうえ再度入力してください。');
      return;
    }

    this.props.onToken(token);
  }

  render() {
    const { is_valid_card_no, is_valid_expired_on, is_valid_cvc } = this.state;

    return (
      <Modal
        show={ this.props.show }
        onHide={ this.handleClose }
        centered
      >
        <Modal.Header closeButton>
          カード情報を入力してください
        </Modal.Header>
        <Modal.Body>
          <Row className='items-middle'>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
              <span>請求金額</span>
            </Col>
            <Col xs={ 8 } className='mypage-content-item-d'>
              <span>{ Util.thousandSeparate(this.props.amount) }円</span>
            </Col>
          </Row>
          <Row className='items-middle'>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
              <span>カード番号</span>
            </Col>
            <Col xs={ 8 } className='mypage-content-item-d'>
              <CardNumberElement onChange={ this.handleNoChanged }/>
            </Col>
          </Row>
          <Row className='items-middle'>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
              <span>有効期限</span>
            </Col>
            <Col xs={ 8 } className='mypage-content-item-d'>
              <CardExpiryElement onChange={ this.handleExpiredOnChanged }/>
            </Col>
          </Row>
          <Row className='items-middle'>
            <Col xs={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
              <span>CVCコード</span>
            </Col>
            <Col xs={ 8 } className='mypage-content-item-d'>
              <CardCVCElement onChange={ this.handleCvcChanged}/>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant=''
            disabled={ !is_valid_card_no || !is_valid_expired_on || !is_valid_cvc }
            className='btn-ghost-dark btn'
            onClick={ this.submit }>
            決済実行
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CheckoutForm.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onToken: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired
}

export default injectStripe(CheckoutForm);
