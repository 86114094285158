import React from 'react';
import logoImg from '../../../assets/images/ton_bebe_logo_g_t.png';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class AdminHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className='header-wrapper bg-blue-mesh' as='header'>
        <Row className='items-middle'>
          <Col md={ 8 }>
            <Link to='/admin'>
              <img src={ logoImg } className='header-logo' alt='Ton Bebe' />
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default AdminHeader;
