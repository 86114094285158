import React from 'react';
import WelcomeHeader from './welcome_header';
import WelcomeContents from './welcome_contents';
import { withRouter } from 'react-router';
import Util from '../../services/util';
import ApiClient from '../../services/api_client';

class Welcome extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillMount = () => {
    const data = Util.parse_query(this.props.location.search);

    if (!data.provider) return;

    let req = ApiClient.postRequestTo(`/api/v1/external_login`, data);

    fetch(req).then((res) => {
      if (!res.ok) {
        this.props.history.push(`/home/failed`);
      }

      return res.json();
    }).then((json) => {
      localStorage.setItem('access_token', json.data.access_token);
      localStorage.setItem('refresh_token', json.data.refresh_token);

      this.props.history.push(`/home/${ json.data.action }`);
    }).catch((err) => {
      console.error(err);

      this.props.history.push(`/home/failed`);
    });
  }

  render() {
    return (
      <div>
        <WelcomeHeader />
        <WelcomeContents />
      </div>
    )
  }
}

export default withRouter(Welcome);
