import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col,
  Button
} from 'react-bootstrap'

class WelcomeContents extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='welcome-contents-wrapper'>
        <Row>
          <Col className='text-center' xsOffset={ 0 } xs={ 12 }>
            <p className='welcome-contents-paragraph'>ログイン処理中です...</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default WelcomeContents;
