import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { Link } from 'react-router-dom';

class MessageBand extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container fluid className='message-band-wrapper bg-blue-babies'>
        <Row>
          <Col md={{ span: 10, offset: 2}}>
            <h2 className='message-band-message'>未来の家族のために<br/>条件にあうドナーを見つけましょう</h2>
          </Col>
        </Row>
        <Row>
          <Col md={ 12 } className='text-center'>
            <Link to='/search' className='message-band-button btn-ghost-dark btn btn-lg'>
              ドナーを探す
            </Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default MessageBand;
