/* eslint no-console:0 */
console.log('Hello World from Ton Bébé')

import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import ScrollToTop from '../components/scroll_to_top';
import { ToastContainer, Slide } from 'react-toastify';
import App from '../components/app';
import Home from '../components/home';
import Static from '../components/static';
import Login from '../components/login';
import Signup from '../components/signup';
import SignupConfirm from '../components/signup_confirm';
import Welcome from '../components/welcome';
import Search from '../components/search';
import DonorProfileView from '../components/donor_profile_view';
import Password from '../components/password';
import Messages from '../components/messages';
import Mypage from '../components/mypage';
import UserAuth from '../components/user_auth';
import AdminAuth from '../components/admin/admin_auth';
import Admin from '../components/admin';
import GA from '../services/google_analytics';
import { ja } from '../locales/ja';

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <BrowserRouter>
      { GA.init() && <GA.RouteTracker /> }
      <Helmet>
        <title>Ton Bébé | 精子提供マッチングサービス</title>
        <meta name="description" content={ `${ ja['site_description'] }子供を望む全ての女性のために安心安全なドナー探しをお手伝いします。` } />
      </Helmet>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact component={ Home } />
          <Route path="/home/:action" exact component={ Home } />
          <Route path="/home" exact component={ Home } />
          <Route path="/static/:page" exact component={ Static } />
          <Route path="/login" exact component={ Login } />
          <Route path="/signup" exact component={ Signup } />
          <Route path="/signup_confirm/:email" exact component={ SignupConfirm } />
          <Route path="/welcome" exact component={ Welcome } />
          <Route path="/search" component={ Search } />
          <Route path="/donor/:user_no" component={ DonorProfileView } />
          <Route path="/password/:action/:token" exact component={ Password } />
          <Route path="/password/:action" exact component={ Password } />
          <UserAuth>
            <Switch>
              <Route path="/messages" component={ Messages } />
              <Route path="/mypage/:menu/:action" component={ Mypage } />
              <Route path="/mypage/:menu" component={ Mypage } />
              <AdminAuth>
                <Switch>
                  <Route path="/admin/:menu/:id/:action" component={ Admin } />
                  <Route path="/admin/:menu" component={ Admin } />
                  <Route path="/admin" component={ Admin } />
                </Switch>
              </AdminAuth>
            </Switch>
          </UserAuth>
        </Switch>
        <ToastContainer
          transition={ Slide }
          hideProgressBar={ true }
          closeButton={ false }
          autoClose={ 3000 }
        />
      </ScrollToTop>
    </BrowserRouter>,
    document.getElementById('app-root')
  )
})
