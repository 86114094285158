import React from 'react';
import ReactDOM from 'react-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Image
} from 'react-bootstrap'
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import ApiClient from '../../services/api_client';
import Auth from '../../services/auth';
import { toast } from 'react-toastify';
import {
  FacebookIcon,
  TwitterIcon
} from 'react-share';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    }
  }

  componentDidMount(){
    this.focusEmail();
  }

  focusEmail = () => {
    let input = ReactDOM.findDOMNode(this.refs.emailInput);
    input && input.select();
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value
    })
  }

  handleKeyPress = (e) => {
    if (e.charCode == 13 && this.state.email && this.state.password) {
      this.handleLogin()
    }
  }

  handleLogin = (e) => {
    ApiClient.post(
      'api/v1/login',
      {
        email: this.state.email,
        password: this.state.password
      },
      (res) => {
        if (!res.ok) {
          toast.error(<span>ログインできませんでした。<br/>入力内容をお確かめの上、もう一度お試しください。</span>);
          this.focusEmail();
          throw Error(`[Login failed] ${res.status} ${res.statusText}`);
        }

        return res.json();
      },
      (json) => {
        localStorage.setItem('access_token', json.data.access_token);
        localStorage.setItem('refresh_token', json.data.refresh_token);

        if (Auth.isAdmin()) {
          this.props.history.push('/admin');
        } else {
          this.props.history.push('/home/login');
        }
      }
    );
  }

  render() {
    return (
      <Container className='login-form-wrapper'>
        <Row>
          <Col sm={{ span: 5, offset: 4 }} lg={ 4 }>
            <Button
              className='btn-sns-logo facebook'
              href={ `/oauth/facebook` }
              variant='light'
              block
            >
              <div className='sns-icon'>
                <FacebookIcon
                  size={ 40 }
                  round
                />
              </div>
              &nbsp;&nbsp;Facebookでログイン
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={{ span: 5, offset: 4 }} lg={ 4 }>
            <Button
              className='btn-sns-logo twitter'
              href={ `/oauth/twitter` }
              variant='light'
              block
            >
              <div className='sns-icon'>
                <TwitterIcon
                  size={ 40 }
                  round
                />
              </div>
              &nbsp;&nbsp;Twitterでログイン
            </Button>
          </Col>
        </Row>
        <Row className='or-row'>
          <Col sm={{ span: 5, offset: 4 }} lg={ 4 }className='text-center'>
             <p>または</p>
          </Col>
        </Row>
        <Form>
          <Form.Group as={ Row }>
            <Form.Label column sm={ 4 } className='login-form-label'>
              Email
            </Form.Label>
            <Col sm={ 5 } lg={ 4 }>
              <Form.Control
                ref='emailInput'
                type='email'
                value={ this.state.email }
                placeholder='ton@bebe.jp'
                onChange={ this.handleEmailChange }
                onKeyPress={ this.handleKeyPress }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={ 4 } className='login-form-label'>
              パスワード
            </Form.Label>
            <Col sm={ 5 } lg={ 4 }>
              <Form.Control
                type='password'
                value={ this.state.password }
                placeholder='********'
                onChange={ this.handlePasswordChange }
                onKeyPress={ this.handleKeyPress }
              />
            </Col>
          </Form.Group>
          <Form.Group as={ Row }>
            <Form.Label column sm={{ span: 3, offset: 4 }} lg={ 2 }>
              <Link
                to='#'
                onClick={ this.handleLogin }
                className='btn btn-ghost-dark btn-block'
              >ログイン</Link>
            </Form.Label>
          </Form.Group>
        </Form>
        <Row className=''>
          <Col sm={{ span: 8, offset: 4}} className=''>
            <Link to='/signup' className='btn-link-dark'>新規登録はこちら</Link><br/>
            <Link to='/password/forgot' className='btn-link-dark'>パスワードをお忘れの方はこちら</Link>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(LoginForm);
