import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/core';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { ClipLoader as Loader } from 'react-spinners';

class Spinner extends React.Component {
  render() {
    return (
      <TransitionGroup>
        {this.props.isLoading &&
          <CSSTransition
            classNames='spinner'
            timeout={{ enter: 500, exit: 300 }}
          >
            <div className='spinner-overlay'>
              <Loader
                css={ css`position: absolute; left: 48%; top: 48%;` }
                size={ 50 }
                color='#777'
                loading={ true }
              />
            </div>
          </CSSTransition>
        }
      </TransitionGroup>
    )
  }
}

Spinner.propTypes = {
  isLoading: PropTypes.bool
}

export {
  Spinner
}
