import React from 'react';
import UserHeader from '../common/user_header';
import DoneeMenus from './donee_menus';
import DoneeProfile from './donee_profile'
import ApproveRecord from './approve_record'
import Footer from '../common/footer';
import Auth from '../../services/auth';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'

class DoneeMypages extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { menu } = this.props;

    let menuPage = null;
    switch (menu) {
      case 'profile':
        menuPage = <DoneeProfile />;
        break;
      case 'record':
        menuPage = <ApproveRecord />;
        break;
    }

    return (
      <div>
        <UserHeader />
        <Container>
          <Row>
            <Col md={ 3 } className='d-none d-md-block'>
              <DoneeMenus activeMenu={ menu } />
            </Col>
            <Col md={ 9 } className='mypage-wrapper'>
              { menuPage }
            </Col>
          </Row>
        </Container>
        <Footer />
      </div>
    )
  }
}

export default DoneeMypages;
