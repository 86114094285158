import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Alert
} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ApiClient from '../../../services/api_client';
import Util from '../../../services/util';
import { Spinner } from '../../common/spinner';

class DonorSemen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collected_on: '',
      volume: 0.0,
      concentration: 0,
      total_count: 0,
      motility: 0,
      morphology: 0,
      total: '',
      status: '',
      expired: false,
      comments: '',
      files: [],
      del_file_ids: []
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donor_semens/me`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Semen] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const donor_semen = json.data || {};
      if (donor_semen.status == 'CERTIFIED') {
        donor_semen.comments = '';
        donor_semen.donor_semen_files = [];
      }
      this.setState({
        collected_on: donor_semen.collected_on ? new Date(donor_semen.collected_on) : '',
        volume: donor_semen.volume || '',
        concentration: donor_semen.concentration || '',
        total_count: donor_semen.total_count || '',
        motility: donor_semen.motility || '',
        morphology: donor_semen.morphology || '',
        total: donor_semen.total || '---',
        status: donor_semen.status || '',
        expired: donor_semen.expired || false,
        comments: donor_semen.comments || '',
        files: donor_semen.donor_semen_files || [],
        del_file_ids: []
      }, this.setTotal);
    }).catch((err) => {
      console.error(err);
    });
  }

  handleDateChange = (date) => {
    this.setState({
      collected_on: date
    })
  }

  handleTextChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value
    }, this.setTotal)
  }

  setTotal = () => {
    const { volume, concentration, total_count, motility, morphology } = this.state;

    let params = 'volume=' + volume;
    params += '&concentration=' + concentration;
    params += '&total_count=' + total_count;
    params += '&motility=' + motility;
    params += '&morphology=' + morphology;

    let req = ApiClient.getRequestTo(`/api/v1/donor_semens_total?${ params }`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET Semen Total] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const total = json.data || '---';
      this.setState({ total: total })
    }).catch((err) => {
      console.error(err);
    });
  }

  handleFileToSelect = (e) => {
    this.fileSelector.click();
  }

  handleFileSelected = (e) => {
    let files = this.state.files;
    if (files.length >= 5) { return }
    files.push({
      image: e.target.files.item(0),
      new: true
    });
    this.setState({
      files: files
    });
  }

  handleFileRemove = (e) => {
    const file_idx = e.currentTarget.dataset.file_idx;
    let { files, del_file_ids } = this.state

    let file = files[file_idx]
    if (!file['new']) {
      del_file_ids.push(file['id'])
    }

    files.splice(file_idx, 1);

    this.setState({
      files: files,
      del_file_ids: del_file_ids
    });
  }

  handleSaveClick = (e) => {
    if (this.state.status == 'CERTIFIED' && !this.state.expired) {
      if (!confirm('保存すると再申請＆承認されるまで未提出扱いとなります。保存しますか？')){
        return;
      }
    }

    const { collected_on } = this.state;
    if (collected_on && !Util.isIn6Month(collected_on)) {
      toast.error('6ヶ月以内の検査結果のみ登録可能です。');
      return
    }

    this.execSave(e);
  }

  execSave = (e) => {
    if (this.state.status == 'REMANDED') {
      const { collected_on, volume, concentration, total_count, files } = this.state;
      if (!collected_on || !volume || !concentration || !total_count || !files || files.length == 0) {
        toast.error('＊は必須項目です');
        return
      }
    }

    this.setState({ loading: true });

    const act = this.state.status == 'REMANDED' ? '再提出' : '保存'

    const formData = new FormData();
    formData.append('collected_on', this.state.collected_on);
    formData.append('volume', this.state.volume);
    formData.append('concentration', this.state.concentration);
    formData.append('total_count', this.state.total_count);
    formData.append('motility', this.state.motility);
    formData.append('morphology', this.state.morphology);
    formData.append('del_file_ids', this.state.del_file_ids);
    for (const idx in this.state.files) {
      const f = this.state.files[idx]
      if (f['new']) {
        formData.append('files[]', f['image']);
      }
    }

    const token = localStorage.getItem('access_token');

    const req =  new Request('/api/v1/donor_semens/me', {
      method: 'PATCH',
      headers: new Headers({
        'Authorization': `Bearer ${token}`
      }),
      body: formData
    });

    fetch(req).then((res) => {
      this.setState({ loading: false });

      if (!res.ok) {
        throw Error(`[Save Std] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success(act + 'しました。');
      this.props.history.push('/mypage/std_semen');
    }).catch((err) => {
      toast.error('保存に失敗しました。入力内容をお確かめの上再度お試しください。');
    });
  }

  render() {
    const { loading, files, status, comments } = this.state;

    let alert_message = null;
    if (status == 'REMANDED') {
      let comments_e = comments;
      comments_e = comments_e.split("<").join("&lt;");
      comments_e = comments_e.split(">").join("&gt;");
      comments_e = comments_e.split("\n").join("<br>");

      alert_message =
        <Alert variant='danger'>
          <span dangerouslySetInnerHTML={{ __html: comments_e }} />
        </Alert>
    }

    let fileList = [];
    if (files.length === 0) {
      fileList.push(<p key='0' className='text-center upload-copy-msg'>-- 書類のコピーを提出してください --</p>)
    } else {
      for (let idx in files) {
        fileList.push(
          <p key={ idx }>
            <span className='d-md-none'>{ idx * 1 + 1 }.&nbsp;</span>
            { files[idx]['image'].name }&nbsp;&nbsp;
            <Button
              variant=''
              className='btn-link-dark btn-lg'
              data-file_idx={ idx }
              onClick={ this.handleFileRemove }
            >
              &times;
            </Button>
          </p>
        );
      }
    }

    const save_btn_text = status == 'REMANDED' ? '再提出' : '保存'

    return (
      <div>
        <Spinner isLoading={ loading } />
        <Row>
          <Col>
            <h2 className='mypage-title'>精液検査結果</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col className='mypage-item-group'>
              { alert_message }
            </Col>
          </Row>
          <Row>
            <Col className='mypage-item-group'>
              <Row className='items-middle'>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>採取日 *</span>
                </Col>
                <Col md={ 4 } className='mypage-content-item-d'>
                  <DatePicker
                    className='form-control'
                    dateFormat='yyyy/MM/dd'
                    selected={ this.state.collected_on }
                    onChange={ this.handleDateChange }
                  />
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 7, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>精液量 *</span>
                </Col>
                <Col xs={ 10 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    step='0.1'
                    id='volume'
                    value={ this.state.volume }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 2 } md={ 1 } className='mypage-content-item-d'>
                  <span>ml</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>精子濃度 *</span>
                </Col>
                <Col xs={ 10 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='concentration'
                    value={ this.state.concentration }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 2 } md={ 1 } className='mypage-content-item-d'>
                  <span>万/ml</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>総精子数 *</span>
                </Col>
                <Col xs={ 10 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='total_count'
                    value={ this.state.total_count }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 2 } md={ 1 } className='mypage-content-item-d'>
                  <span>万</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>運動率</span>
                </Col>
                <Col xs={ 10 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='motility'
                    value={ this.state.motility }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 2 } md={ 1 } className='mypage-content-item-d'>
                  <span>%</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={ 12 } md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>正常形態率</span>
                </Col>
                <Col xs={ 10 } md={ 3 } className='mypage-content-item-d'>
                  <Form.Control
                    type='number'
                    id='morphology'
                    value={ this.state.morphology }
                    onChange={ this.handleTextChange }
                  />
                </Col>
                <Col xs={ 2 } md={ 1 } className='mypage-content-item-d'>
                  <span>%</span>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col md={{ span: 7, offset: 1 }} className='mypage-content-item-hr'>
                </Col>
              </Row>
              <Row className='items-middle'>
                <Col xs={{ span: 5, offset: 1 }} md={{ span: 3, offset: 1 }} className='mypage-content-lg-item-h'>
                  <span>総合判定</span>
                </Col>
                <Col xs={ 6 } md={ 4 } className='mypage-content-lg-item-d'>
                  <span>{ this.state.total }</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-item-group'>
              <Row className=''>
                <Col md={{ span: 3, offset: 1 }} className='mypage-content-item-h'>
                  <span>検査結果書類 *</span>
                </Col>
                <Col md={ 8 } className='mypage-content-item-d'>
                  <Row>
                    <Col className='mypage-content-item-d'>
                      { fileList }
                    </Col>
                  </Row>
                  <Row>
                    <Col md={ 8 } lg={ 6 }>
                      <Button
                        className='btn-ghost-dark'
                        disabled={ files.length >= 5}
                        onClick={ this.handleFileToSelect }
                        block
                      >
                        追加アップロード
                      </Button>
                      <input
                        type='file'
                        className='invisible'
                        ref={el => this.fileSelector = el}
                        onChange={ this.handleFileSelected }
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={ 12 } className='mypage-item-group'>
              <Row className='items-middle'>
                <Col md={{ span: 10, offset: 1 }} className=''>
                  <h4>【注意事項】</h4>
                  <p>＊検査結果書類は<strong className='text-orange'>「検査機関」</strong>と<strong className='text-orange'>「検査ID（報告書番号、キットIDなど）」</strong>がわかるものを提出してください。</p>
                  <p>＊病院での検査結果の場合、<strong className='text-orange'>「診察券」「診療明細」</strong>のコピーも提出してください。</p>
                  <p>＊提出書類をもとに検査機関に問い合わせを行う場合があります。</p>
                  <p>＊書類の信憑性を確認できない場合は登録をお断りします。書類の偽造など悪質な場合は、返金致しませんのでご了承ください。</p>
                  <p>＊書類の<strong className='text-red'>偽造/変造は犯罪</strong>です。刑法により処罰される可能性があります。</p>
                  <h4>【入力項目と書類について】</h4>
                  <p>＊検査結果書類はウェブ画面のスクリーンショットでも可能です。</p>
                  <p>＊検査結果書類の画像を他ユーザーが閲覧することはありません。</p>
                  <p>＊採取日の記載が書類にない場合は、検査日/受付日などの日付でも登録可能です。</p>
                  <p>＊書類に記載のある項目は全て記入してください。記入漏れがある場合は、運営事務局にて書類を基に訂正を行います。</p>
                  <p>＊正常形態率=（100% - 奇形率）になります。</p>
                  <p>＊正常形態率に幅がある場合は最大値を記入してください。</p>
                  <p>＊検査結果の有効期間は採取日から１年としております。</p>
                  <p>＊6ヶ月以内の検査結果のみ登録可能です。</p>
                  <p>＊個人情報の取り扱いについては<Link to='/static/privacy'>プライバシーポリシー</Link>のページをご覧ください。</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 3, offset: 1 }} className='mypage-submit'>
              <Button
                className='btn-ghost-dark'
                onClick={ this.handleSaveClick }
                block
              >
                { save_btn_text }
              </Button>
            </Col>
            <Col md={ 3 } className='mypage-submit'>
              <Link to='/mypage/std_semen' className='btn-ghost-dark btn btn-block'>
                戻る
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default withRouter(DonorSemen);
