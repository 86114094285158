import React from 'react';
import { Helmet } from 'react-helmet';
import VisitorHeader from '../common/visitor_header';
import UserHeader from '../common/user_header';
import PageTitle from '../common/page_title';
import Conditions from './conditions';
import ResultTable from './result_table';
import Footer from '../common/footer';
import ApiClient from '../../services/api_client';
import Auth from '../../services/auth';
import Util from '../../services/util';
import { Spinner } from '../common/spinner';
import { ja } from '../../locales/ja';
import InfiniteScroll from 'react-infinite-scroller';

class Search extends React.Component {
  constructor(props) {
    super(props);

    if (props.location.state) {
      this.state = props.location.state;
    } else {
      const query_conds = Util.parse_query(this.props.location.search);
      this.state = {
        loading: false,
        donors: [],
        page: 0,
        hasMore: true,
        conditions: {
          area: query_conds.area ? query_conds.area.split(',') : [],
          age: query_conds.age ? query_conds.age.split(',') : [],
          blood_type: query_conds.blood_type ? query_conds.blood_type.split(',') : [],
          height: query_conds.height ? query_conds.height.split(',') : [],
          body_figure: query_conds.body_figure ? query_conds.body_figure.split(',') : [],
          college_type: query_conds.college_type ? query_conds.college_type.split(',') : [],
          major_type: query_conds.major_type ? query_conds.major_type.split(',') : [],
          marriage: query_conds.marriage ? query_conds.marriage.split(',') : [],
          children: query_conds.children ? query_conds.children.split(',') : [],
          std_certified_only: query_conds.std_certified_only ? query_conds.std_certified_only.split(',') : [],
          semen_total: query_conds.semen_total ? query_conds.semen_total.split(',') : [],
          methods: query_conds.methods ? query_conds.methods.split(',') : [],
          gratuity: query_conds.gratuity ? query_conds.gratuity.split(',') : []
        }
      }
    }
  }

  componentWillMount = () => {
    if (!this.props.location.state) {
      this.search();
    }
  }

  search = () => {
    console.log('conditions', this.state.conditions)
    this.setState({
      donors: [],
      page: 0,
      hasMore: true,
    }, () => { this.loadMore() });
  }

  loadMore = () => {
    if (this.state.loading) { return };

    this.setState({ loading: true });

    const limit = 25;
    const offset = limit * this.state.page;

    let req = ApiClient.postRequestTo(
      '/api/v1/donors/search',
      { ...this.state.conditions, limit, offset }
    );

    fetch(req).then((res) => {
      if (!res.ok) {
        this.setState({ loading: false });
        throw Error(`[GET User] ${res.status} ${res.statusText}`);
      }

      this.setState(prevState => {
        return {
          loading: false,
          page: prevState.page + 1
        };
      });

      return res.json();
    }).then((json) => {
      const users = json.data;

      const new_donors = users.map(user => {
        const donor_basic_info = user.donor_basic_info || {};
        const donor_physical_info = user.donor_physical_info || {};
        const donor_request = user.donor_request || {};
        const donor_std = user.donor_std || {};
        const donor_semen = user.donor_semen || {};
        const std_collected_on = new Date(donor_std.collected_on + 'T00:00:00');
        const semen_collected_on = new Date(donor_semen.collected_on + 'T00:00:00');
        const today = new Date();
        const last_year = new Date(today.getFullYear()-1, today.getMonth(), today.getDate());
        return {
          user_no: user.user_no,
          name: user.name || '',
          active_in_hour: user.active_in_hour,
          age: donor_basic_info.birth_date ? Util.getAge(donor_basic_info.birth_date) : '---',
          areas: donor_basic_info.areas || [],
          college_type: donor_basic_info.college_type || '',
          major_type: donor_basic_info.major_type || '',
          college: donor_basic_info.college || '',
          major: donor_basic_info.major || '',
          marriage: donor_basic_info.marriage || '',
          son: donor_basic_info.son || '',
          daughter: donor_basic_info.daughter || '',
          comments: donor_basic_info.comments || '',
          blood_type: donor_physical_info.blood_type || '---',
          blood_rh: donor_physical_info.blood_rh || '',
          height: donor_physical_info.height || '---',
          weight: donor_physical_info.weight || '---',
          body_figure: donor_physical_info.body_figure || '',
          methods: donor_request.methods || [],
          gratuity: donor_request.gratuity || '',
          std_certified: donor_std.status === 'CERTIFIED' && std_collected_on > last_year,
          semen_total: donor_semen.status === 'CERTIFIED' && semen_collected_on > last_year ? donor_semen.total : ''
        }
      })

      const { donors } = this.state;

      donors.push(...new_donors);
      console.log(donors.length)
      this.setState({
        donors,
        hasMore: new_donors.length === limit
      }, () => {this.props.history.replace('/search', this.state)});
    }).catch((err) => {
      console.error(err);
    });
  }

  clear = (e) => {
    this.setState({
      conditions: {
        area: [],
        age: [],
        blood_type: [],
        height: [],
        body_figure: [],
        college_type: [],
        major_type: [],
        marriage: [],
        children: [],
        std_certified_only: [],
        semen_total: [],
        methods: [],
        gratuity: []
      }
    })
  }

  handleCondChecked = (e) => {
    const data_type = e.currentTarget.dataset.type;
    const data_key = e.currentTarget.dataset.key;
    let conds = this.state.conditions;
    if (e.target.checked) {
      if (conds[data_type].indexOf(data_key) < 0) {
        conds[data_type].push(data_key);
      }
    } else {
      const idx = conds[data_type].indexOf(data_key);
      if (idx >= 0) {
        conds[data_type].splice(idx, 1);
      }
    }
    this.setState({
      conditions: conds
    });
  }

  render() {
    let headerSet = null;
    if (Auth.isLoggedIn()) {
      headerSet = <UserHeader />
    } else {
      headerSet = <VisitorHeader />;
    }

    return (
      <div>
        <Spinner isLoading={ this.state.loading } />
        <Helmet>
          <title>Ton Bébé | ドナー検索</title>
          <meta name='description' content={ `${ ja['site_description'] }このページで精子ドナーを見つけましょう。`} />
        </Helmet>
        { headerSet }
        <PageTitle title='ドナー検索' />
        <Conditions
          handleCondChecked={ this.handleCondChecked }
          handleSearchClick={ this.search }
          handleClearCondClick={ this.clear }
          conditions={ this.state.conditions }
        />
        <InfiniteScroll
            loadMore={ this.loadMore }
            initialLoad={ false }
            hasMore={ this.state.hasMore }
        >
          <ResultTable donors={ this.state.donors }/>
        </InfiniteScroll>
        <Footer />
      </div>
    )
  }
}

export default Search;
