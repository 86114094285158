const getAge = (birth_date) => {
  const bd  = new Date(birth_date);

  const now = new Date();
  const thisBd = new Date(now.getFullYear(), bd.getMonth(), bd.getDate());

  const age = now.getFullYear() - bd.getFullYear();

  //今年の誕生日を迎えていなければage-1を返す
  return (now < thisBd) ? age-1 : age;
}

const isIn6Month = (date) => {
  const now = new Date();

  let sixMonthAgo = new Date();
  sixMonthAgo.setMonth(now.getMonth()-6);
  sixMonthAgo.setDate(now.getDate()-1);

  return sixMonthAgo < new Date(date);
}

import { ja } from '../locales/ja';
const val = (ctgr, key) => {
  return ja[ctgr][key]
}

const thousandSeparate = (num) => {
  return num && String(num).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

const parse_query = (location_search) => {
  let arg = new Object;
  const pair = location_search.substring(1).split('&');
  for (let i=0; pair[i]; i++) {
    const kv = pair[i].split('=');
    arg[kv[0]]=kv[1];
  }
  return arg;
}

const toLocalDate = (utc_string) => {
  const utc = new Date(utc_string);
  const offset = -1 * utc.getTimezoneOffset() / 60;
  const local = new Date(utc.getTime() + (offset * 3600000));
  return local;
}

const autoLink = (str) =>  {
    var regexp_url = /((h?)(ttps?:\/\/[a-zA-Z0-9.\-_@:/~?%&;=+#',()*!]+))/g; // ']))/;
    var regexp_makeLink = function(all, url, h, href) {
        return '<a href="h' + href + '" target="_blank">' + url + '</a>';
    }

    return str.replace(regexp_url, regexp_makeLink);
}

export default {
  getAge,
  isIn6Month,
  val,
  thousandSeparate,
  parse_query,
  toLocalDate,
  autoLink
}
