import React from 'react';
import {
  Row,
  Col,
  Table
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Util from '../../../services/util';

class Records extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      record_count: 0,
      records: []
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donor_records`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET donor_records] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const records = json.data.map(record => {
        return {
          donee: record.donee.name,
          date: record.donated_at,
          area: Util.val('area', record.area),
          method: Util.val('methods', record.meth),
          status: Util.val('record_status', record.status)
        }
      })
      this.setState({
        record_count: json.data.length,
        records: records
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { record_count, records } = this.state;

    let recordList = [];
    for (let i in records) {
      const record = records[i];
      recordList.push(
        <tr key={ i }>
          <td>{ record.donee }</td>
          <td className='d-none d-md-table-cell'>{ record.date }</td>
          <td className='d-none d-md-table-cell'>{ record.area }</td>
          <td className='d-none d-md-table-cell'>{ record.method }</td>
          <td className='d-none d-md-table-cell'>{ record.status }</td>
          <td className='d-md-none'>
            【{ record.status }】<br/>
            日付： { record.date }<br/>
            エリア： { record.area }<br/>
            提供方法： { record.method }
          </td>
        </tr>
      );
    }

    return (
      <div>
        <Row>
          <Col>
            <h2 className='mypage-title'>提供実績<small>(承認済み)</small>:{ record_count }回</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col xs={ 6 } md={ 4 } className='mypage-submit'>
              <Link to='/mypage/record/new' className='btn-ghost-dark btn btn-block'>
                新規登録
              </Link>
            </Col>
          </Row>
          <Row>
            <Col className='record-table-wrapper'>
              <Table responsive>
                <thead>
                  <tr>
                    <th>提供を受けた人</th>
                    <th>日付</th>
                    <th>エリア</th>
                    <th>提供方法</th>
                    <th>ステータス</th>
                  </tr>
                </thead>
                <tbody>
                  { recordList }
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

export default Records;
