import React from 'react';
import logoImg from '../../../assets/images/ton_bebe_logo_g_t.png';
import {
  Container,
  Row,
  Col,
  Dropdown
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import Auth from '../../services/auth';
import ApiClient from '../../services/api_client';

class UserHeader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      unread_count: 0,
      pending_count: 0
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/users/${ Auth.userId() }/name`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET User.name] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const user = json.data;
      this.setState({
        name: user.name
      });
    }).catch((err) => {
      console.error(err);
    });

    let req_2 = ApiClient.secureGetRequestTo(`/api/v1/unread_msg_count`);

    fetch(req_2).then((res) => {
      if (!res.ok) {
        throw Error(`[GET message unread_msg_count] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      this.setState({
        unread_count: json.data.unread_count
      });
    }).catch((err) => {
      console.error(err);
    });


    if (Auth.isDonee()) {
      let req_3 = ApiClient.secureGetRequestTo(`/api/v1/donor_records/pending_count`);

      fetch(req_3).then((res) => {
        if (!res.ok) {
          throw Error(`[GET message pending_count] ${res.status} ${res.statusText}`);
        }

        return res.json();
      }).then((json) => {
        this.setState({
          pending_count: json.data.pending_count
        });
      }).catch((err) => {
        console.error(err);
      });
    }
  }

  handleLogout = (e) => {
    let req = ApiClient.secureDeleteRequestTo(`/api/v1/users/${ Auth.userId() }/logout`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[DELETE logout] ${res.status} ${res.statusText}`);
      }
      // Do nothing
    }).catch((err) => {
      console.error(err);
    });

    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');

    this.props.history.push('/');
  }

  render() {
    const unread_badge_cls = this.state.unread_count > 0 ? 'unread-badge' : '';
    const name_badge_cls = this.state.pending_count > 0 ? 'unread-badge' : '';
    const profile_badge_cls = this.state.pending_count > 0 ? 'unread-badge' : '';

    return (
      <Container fluid className='header-wrapper bg-blue-mesh' as='header'>
        <Row className='items-middle'>
          <Col lg={ 9 } md={ 7 } xs={ 6 }>
            <Link to='/'>
              <img src={ logoImg } className='header-logo' alt='Ton Bebe' />
            </Link>
          </Col>
          <Col lg={ 2 } md={ 3 } className='text-center d-none d-md-block'>
            <Link to='/messages' className='btn-link-dark text-right btn btn-lg'>
              メッセージ
            </Link>
            <span className={ unread_badge_cls } />
          </Col>
          <Col lg={ 1 } md={{ span: 2, offset: 0 }} xs={{ span: 3, offset: 3 }}className='text-center'>
            <Dropdown alignRight>
              <Dropdown.Toggle id='my-menu' variant='default' className='btn-circle'>
                { this.state.name }
                <span className={ name_badge_cls } />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item as='span' className='header-menu-item'>
                  <Link to='/mypage/profile'>
                    プロフィール
                    <span className={ profile_badge_cls } />
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item as='span' className='header-menu-item d-md-none'>
                  <Link to='/messages'>
                    メッセージ
                    <span className={ unread_badge_cls } />
                  </Link>
                </Dropdown.Item>
                <Dropdown.Divider className='d-md-none'/>
                { Auth.isDonee() &&
                  <Dropdown.Item as='span' className='header-menu-item d-md-none'>
                    <Link to='/mypage/record'>
                      提供実績承認
                    </Link>
                  </Dropdown.Item> }
                { Auth.isDonor() &&
                  <Dropdown.Item as='span' className='header-menu-item d-md-none'>
                    <Link to='/mypage/account'>
                      アカウント
                    </Link>
                  </Dropdown.Item> }
                { Auth.isDonor() &&
                  <Dropdown.Item as='span' className='header-menu-item d-md-none'>
                    <Link to='/mypage/std_semen'>
                      検査結果登録
                    </Link>
                  </Dropdown.Item> }
                { Auth.isDonor() &&
                  <Dropdown.Item as='span' className='header-menu-item d-md-none'>
                    <Link to='/mypage/record'>
                      提供実績登録
                    </Link>
                  </Dropdown.Item> }
                <Dropdown.Divider />
                <Dropdown.Item as='span' className='header-menu-item'>
                  <Link 
                    to='#'
                    onClick={ this.handleLogout }
                  >ログアウト</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default withRouter(UserHeader);
