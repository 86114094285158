import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import { ja } from '../../locales/ja';

class Commerce extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='static-contents-wrapper'>
        <Helmet>
          <title>Ton Bébé | 特定商取引法に基づく表記</title>
          <meta name='description' content={ `${ ja['site_description'] }このページではTon Bébéの特定商取引法に基づく表記を提示します。` } />
        </Helmet>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>サービス名</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>Ton Bébé （トンべべ）</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>運営責任者</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>柿本 玄</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>サイトURL</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>
              <a href="https://www.tonbebe.jp">https://www.tonbebe.jp</a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>Email</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>
              <a href="mailto:info@tonbebe.jp">info@tonbebe.jp</a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>販売価格</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>サービスの該当ページに記載</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>送料</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>なし</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>お支払い方法</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>クレジットカード</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>商品代金以外の必要経費</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>なし</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>商品引き渡し時期・方法</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>入金確認および弊社にて提出資料の審査が完了した後、ドナー一覧に表示されます。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>返品・返金について</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>申し込み後・決済後の返品・交換には応じられません。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>※上記以外の事項に関しましては、お取引の際に請求があれば遅延なく提示いたします。</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Commerce;
