import React from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  Badge
} from 'react-bootstrap'
import { withRouter } from 'react-router';
import ApiClient from '../../services/api_client';

class DoneeMenus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pending_count: 0
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donor_records/pending_count`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET message pending_count] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      this.setState({
        pending_count: json.data.pending_count
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  handleMenuSelect = (key) => {
    this.props.history.push('/mypage/' + key);
  }

  render() {
    const { activeMenu } = this.props;

    return (
      <Nav className='flex-column mypage-nav'
        activeKey={ activeMenu }
        onSelect={ this.handleMenuSelect }
      >
        <Nav.Link className='mypage-nav-item' eventKey='profile'>プロフィール</Nav.Link>
        <Nav.Link className='mypage-nav-item' eventKey='record'>
          提供実績承認
          &nbsp;
          {this.state.pending_count > 0 && <Badge variant="secondary">未承認</Badge> } 
        </Nav.Link>
      </Nav>
    )
  }
}

DoneeMenus.propTypes = {
  activeMenu: PropTypes.string
}

export default withRouter(DoneeMenus);
