import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import Util from '../../services/util';

class SemenInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { semen } = this.props;

    let cert_text = '※診断書未登録';
    let cert_class = 'text-red';
    let total = '---';
    let volume = '---';
    let concentration = '---';
    let total_count = '---';
    let motility = '---';
    let morphology = '---';
    let collected_on = '---';
    if (semen.certified) {
      cert_text = '※診断書登録済';
      cert_class = 'text-blue';
      total = semen.total;
      volume = semen.volume;
      concentration = semen.concentration;
      total_count = semen.total_count;
      motility = semen.motility;
      morphology = semen.morphology;
      collected_on = semen.collected_on.replace(/-/g, '/');
    }

    return (
      <Container className='profile-content-wrapper'>
        <Row>
          <Col className='profile-content-box'>
            <Row>
              <Col md={ 12 } className='profile-content-title'>
                <span>精液検査結果<small className={ cert_class }>{ cert_text }</small></span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-lg-item-h'>
                <span>精子品質</span>
              </Col>
              <Col xs={ 2 } md={ 1 } className='profile-content-lg-item-d'>
                <span>{ total }</span>
              </Col>
              <Col xs={ 5 } md={ 8 } className='profile-content-sm-item-d'>
                <span>＊詳細は<a href='/static/help#help-sperm-calc'>こちら</a></span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>精液量</span>
              </Col>
              <Col xs={ 4 } md={ 1 } className='profile-content-item-d'>
                <span>{ volume || '---' }</span>
              </Col>
              <Col xs={ 3 } md={ 4 } className='profile-content-item-d'>
                <span>ml</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>精子濃度</span>
              </Col>
              <Col xs={ 4 } md={ 1 } className='profile-content-item-d'>
                <span>{ Util.thousandSeparate(concentration) || '---' }</span>
              </Col>
              <Col xs={ 3 } md={ 4 } className='profile-content-item-d'>
                <span>万/ml</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>総精子数</span>
              </Col>
              <Col xs={ 4 } md={ 1 } className='profile-content-item-d'>
                <span>{ Util.thousandSeparate(total_count) || '---' }</span>
              </Col>
              <Col xs={ 3 } md={ 4 } className='profile-content-item-d'>
                <span>万</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>運動率</span>
              </Col>
              <Col xs={ 4 } md={ 1 } className='profile-content-item-d'>
                <span>{ motility || '---' }</span>
              </Col>
              <Col xs={ 3 } md={ 4 } className='profile-content-item-d'>
                <span>%</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>正常形態率</span>
              </Col>
              <Col xs={ 4 } md={ 1 } className='profile-content-item-d'>
                <span>{ morphology || '---' }</span>
              </Col>
              <Col xs={ 3 } md={ 4 } className='profile-content-item-d'>
                <span>%</span>
              </Col>
            </Row>
            <Row className='items-middle'>
              <Col xs={ 5 } md={{ span: 2, offset: 1 }} className='profile-content-item-h'>
                <span>採取日</span>
              </Col>
              <Col xs={ 7 } md={ 2 } className='profile-content-item-d'>
                <span>{ collected_on }</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

SemenInfo.propTypes = {
  semen: PropTypes.object
}

export default SemenInfo;
