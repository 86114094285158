import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ja } from '../../locales/ja';

class Flow extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className='static-contents-wrapper'>
        <Helmet>
          <title>Ton Bébé | 精子提供の流れ</title>
          <meta name='description' content={ `${ ja['site_description'] }このページではTon Bébéを利用して精子提供を行う/受けるまでの流れを説明します。` } />
        </Helmet>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>1. ドナーを探す</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>Ton Bébéには誰かの役に立ちたい、という思いでご登録していただいているドナーがたくさんおります。地域・血液型などの条件や精子検査の結果などからご自身の希望のドナーを探しましょう。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>2. メッセージ交換</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>初めての人と話すのは緊張しますが、いつでもやめることができますので、軽い気持ちでメッセージを送ってみてください。LINE IDやZoomのリンクも送ることができますので、実際に会話してみるのも良いでしょう。<br/>ご自身の条件に合っていることが確認できたのち、提供場所・提供方法・謝礼などのドナーの条件を確認しましょう。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>3. 面談</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>実際に精子提供を受ける前に、実際に会ってコミュニケーションをとる機会を持ちましょう。<br/>相手のことを知ることで、お互いに信頼感や安心感を得られ、この後の活動がスムーズに進むことでしょう。<br/>性病や精子検査の結果、その他の証明書も面談の場で確認するようにしましょう。<br/>※会う場所はカフェやホテルのロビーなど人目のある場所にしてください。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>4. 条件の確認</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>提供日時や方法について希望を伝えた上でドナーと条件を相互に確認して合意しましょう。<br/>交通費などの実費は、女性が負担することが原則となっております。また、謝礼として１回につき１万円程度もしくはドナーの希望金額をお支払いすることをお勧めします。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>5. 精子提供</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>事前に取り決めた日時、場所、方法で精子提供を行います。<br/>精子提供の方法については<Link to='/static/method'>こちら</Link>を参考にしてください。</p>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <h2 className='static-contents-title'>6. 実績登録承認</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={ 12 }>
            <p className='static-contents-paragraph'>提供実績はドナーのプロフィールに回数で表示されます。精子提供が行われると、後日、女性にドナーより承認依頼が届きます。<br/>ドナーの方の今後の活動の助けになりますので、提供を受けた女性は快く承認しましょう。<br/>※提供実績は回数のみ表示され、提供を受けた女性が公開されることはありません</p>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default Flow;
