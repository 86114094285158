import React from 'react';
import DonorMypages from '../donor_mypages'
import DoneeMypages from '../donee_mypages'
import Auth from '../../services/auth';

class Mypage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { menu, action } = this.props.match.params;

    let mypages;
    if (Auth.isDonor()) {
      mypages = <DonorMypages menu={ menu } action={ action } />;
    } else {
      mypages = <DoneeMypages menu={ menu } />;
    }

    return (
      <div>
        { mypages }
      </div>
    )
  }
}

export default Mypage;
