import React from 'react';
import {
  Row,
  Col,
  Form,
  Button
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ApiClient from '../../../services/api_client';
import Util from '../../../services/util';
import Auth from '../../../services/auth';
import { Spinner } from '../../common/spinner';
import {Elements, StripeProvider} from 'react-stripe-elements';
import CheckoutForm from '../../common/checkout_form';

class DonorSubmit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      std: {},
      semen: {},
      std_ready: false,
      semen_ready: false,
      course: '',
      show_pay_modal: false
    }
  }

  componentWillMount = () => {
    let req = ApiClient.secureGetRequestTo(`/api/v1/donors/${ Auth.userId() }/show_std_semen`);

    fetch(req).then((res) => {
      if (!res.ok) {
        throw Error(`[GET StdSemen] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      const std = json.data.std || {};
      const semen = json.data.semen || {};

      const std_collected_on = std.collected_on;
      const { hiv, syphilis, chlamydia, gonorrhea, donor_std_files } = std;
      const std_required_filled = std_collected_on && hiv && syphilis && chlamydia && gonorrhea && donor_std_files && donor_std_files.length > 0;
      const std_ready = std.status !== 'SUBMITTED' && std.total === 'MINUS' && std_required_filled && Util.isIn6Month(std_collected_on);

      const semen_collected_on = semen.collected_on;
      const { volume, concentration, total_count, donor_semen_files } = semen;
      const semen_required_filled = semen_collected_on && volume && concentration && total_count && donor_semen_files && donor_semen_files.length > 0;
      const semen_ready = semen.status !== 'SUBMITTED' && semen_required_filled && Util.isIn6Month(semen_collected_on);

      this.setState({
        email: json.data.email,
        std: std,
        semen: semen,
        std_ready: std_ready,
        semen_ready: semen_ready,
        course: std_ready ? 'std' : semen_ready ? 'semen' : ''
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  handleFormCheckChecked = (e) => {
    if (e.target.checked) {
      this.setState({
        [e.target.name]: e.target.value
      })
    }
  }

  goPayment = () => {
    this.setState({ show_pay_modal: true })
  }

  handleClose = () => {
    this.setState({ show_pay_modal: false })
  }

  onToken = (token) => {
    this.setState({ loading: true, show_pay_modal: false });

    let req = ApiClient.securePostRequestTo(
      `/api/v1/create_charge`,
      {'user_id': Auth.userId(), 'course': this.state.course, 'token': token }
    );

    fetch(req).then((res) => {
      this.setState({ loading: false });
      if (!res.ok) {
        throw Error(`[Update account] ${res.status} ${res.statusText}`);
      }

      return res.json();
    }).then((json) => {
      toast.success('決済が完了しました。');
    }).catch((err) => {
      toast.error('決済処理に失敗しました。時間をおいてお試しください。');
    });
  }

  render() {
    const { loading, email, std, semen, std_ready, semen_ready, course, show_pay_modal } = this.state;

    let std_class = '';
    let std_reason = '';
    let semen_class = '';
    let semen_reason = '';
    if (!std_ready) {
      if (std.status === 'SUBMITTED') {
        std_class = 'text-blue'
        std_reason = '＊提出書類の確認中です。';
      } else {
        std_class = 'text-red'
        std_reason = '＊全ての必須項目および書類の提出が必要です。（6ヶ月以内のみ有効）';
      }
    }
    if (!semen_ready) {
      if (semen.status === 'SUBMITTED') {
        semen_class = 'text-blue'
        semen_reason = '＊提出書類の確認中です。';
      } else {
        semen_class = 'text-red'
        semen_reason = '＊全ての必須項目および書類の提出が必要です。（6ヶ月以内のみ有効）';
      }
    }

    return (
      <div>
        <Spinner isLoading={ loading } />
        <Row>
          <Col>
            <h2 className='mypage-title'>検査結果登録申請</h2>
          </Col>
        </Row>
        <div className='mypage-content-wrapper'>
          <Row>
            <Col className='mypage-item-group'>
              <Row>
                <Col className=''>
                  <p>入金確認後、当サービス運営事務局にて内容を確認をいたします。</p>
                  <p>内容に不備がなければ72時間以内に登録内容が公開されます。</p>
                  <p>申請内容に不備があった場合は差し戻しをいたしますので、修正をしてください。</p>
                  <p>＊公開されるのは数値のみです。提出書類の画像が公開されることはありません。</p>
                  <p>＊性病検査は「陽性」があると申請できません。</p>
                  <p>＊支払い方法はクレジットカードのみとなります。</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col className='mypage-item-group'>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Check
                      name='course'
                      value='std'
                      checked={ course === 'std' }
                      disabled={ !std_ready }
                      onChange={ this.handleFormCheckChecked }
                      label={
                        <div>
                          <span>性病検査結果のみ申請する（3,000円）</span>
                          <span className={ std_class }>{ std_reason }</span>
                        </div>
                      }
                    />
                    <Form.Check
                      name='course'
                      value='semen'
                      checked={ course === 'semen' }
                      disabled={ !semen_ready }
                      onChange={ this.handleFormCheckChecked }
                      label={
                        <div>
                          <span>精液検査結果のみ申請する（3,000円）</span>
                          <span className={ semen_class }>{ semen_reason }</span>
                        </div>
                      }
                    />
                    <Form.Check
                      name='course'
                      value='both'
                      checked={ course === 'both' }
                      disabled={ !std_ready || !semen_ready }
                      onChange={ this.handleFormCheckChecked }
                      label='両方とも申請する（5,000円）'
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={ 5 } className='mypage-submit'>
              <Button
                variant=''
                className='btn-ghost-dark'
                disabled={ course === '' }
                onClick={ this.goPayment }
                block
              >入金手続きに進む</Button>
            </Col>
            <Col md={ 5 } className='mypage-submit'>
              <Link to='/mypage/std_semen' className='btn-ghost-dark btn btn-block'>
                戻る
              </Link>
            </Col>
          </Row>
          <Row>
            <StripeProvider apiKey={ window.env.STRIPE_PK }>
              <Elements>
                <CheckoutForm
                  show={ show_pay_modal }
                  handleClose={ this.handleClose }
                  name={ email }
                  onToken={ this.onToken }
                  amount={ course === 'both' ? 5000 : 3000 }
                />
              </Elements>
            </StripeProvider>
          </Row>
        </div>
      </div>
    )
  }
}

export default DonorSubmit;
